import { Empty, notification } from "antd";
import esES from 'antd/lib/locale/es_ES';
import { postBanner, putBanner } from "api";
import { CommonSearchForm, InternalPageLayout, Table } from "components";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bannerColumns } from "utils/tableColumns";
import BannerFormModal from "./BannerFormModal";
import { cleanCurrentBanner } from "store/catalogs";
import { setBanners } from "store/catalogs/catalogs_t";

const Banners = () => {
    const [modalShowed, setModalShowed] = useState(false);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const { banner, banners } = useAppSelector(({ catalogs }) => catalogs);
    const { data, status } = banners;

    const registerBanner = async (body:any) => {
        try {
            if (!isLoading) setIsLoading(true);
            const { success, errors } = Object.keys(banner).length > 0 ?
            await putBanner(banner.id, body)
            :
            await postBanner(body);
            if (success === "true") {
                dispatch(cleanCurrentBanner());
                dispatch(setBanners());
                onCloseModal();
                notification.success({
                    message: `${Object.keys(banner).length > 0 ? "Edición" : "Registro"} de Banner`,
                    description: `${Object.keys(banner).length > 0 ? "La edición" : "El registro"} se realizó de forma exitosa`
                });
            } else {
                notification.error({
                    message: `${Object.keys(banner).length > 0 ? "Edición" : "Registro"} de Banner`,
                    description: errors
                });
            }
            setIsLoading(false);
        } catch (error:any) {
            setIsLoading(false);
            notification.error({
                message: `${Object.keys(banner).length > 0 ? "Edición" : "Registro"} de Banner`,
                description: error.message
            });
        }
    }

    const onCloseModal = () => {
        setModalShowed(false);
        dispatch(cleanCurrentBanner());
    }

    const filterBanners = () => {
        return data.filter((item:any) => {
            const { name, description } = item;
            const tempName = name ? name.toLowerCase() : '';
            const tempDesc = description ? description.toLowerCase() : '';
            return tempName.match(search.toLowerCase()) || tempDesc.match(search.toLowerCase())
        });
    }

    useEffect(() => {
       dispatch(setBanners());
    }, [])
    
    return (
        <InternalPageLayout title="Banners">
            <CommonSearchForm 
                clickAdd={() => setModalShowed(true)}
                search={search}
                onSearch={({ target }:any) => setSearch(target.value.trimStart())}
            />
            <Table
                dataSource={search ? filterBanners() : data || []}
                loading={isLoading || status.fetching}
                localeProv={esES}
                columns={bannerColumns(dispatch, () => setModalShowed(true))}
                rowKey={"id"}
                size="small"
                locale={{
                    triggerDesc: 'Ordenar de forma descendente',
                    triggerAsc: 'Ordenar de forma ascendente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No hay datos"
                    />
                    )
                }}
                scroll={{ x: 'auto' }}
                pagination={{ defaultPageSize: 10 }}
            />
            {modalShowed &&
            <BannerFormModal
                okHandler={registerBanner}
                visible={modalShowed}
                closeModal={onCloseModal}
            />}
        </InternalPageLayout>
    )
}
export default Banners;
