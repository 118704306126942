import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'store/globalactions';
import { ServiceSchema } from 'store/schema';

export const serviceSlice = createSlice({
   name: 'HUNTERS',
   initialState: ServiceSchema,
   reducers: {
      setCurrentService: (state, { payload }) => {
         state.currentService = payload;
         state.status = { state: 'SUCCESS', fetching: false },
         state.errorMessage = '';
      },
      cleanCurrentService: (state) => {
         state.currentService = ServiceSchema.currentService;
         state.status = { state: '', fetching: false },
         state.errorMessage = '';
      }
   },
   extraReducers: (builder) => {
      builder.addCase(logout, () => {
         return ServiceSchema;
      });
   },
});

export const {
   setCurrentService, cleanCurrentService
} = serviceSlice.actions;