import { Button, Divider, Form, InputNumber, Modal } from "components";
import { Col, Modal as M, Row } from "react-bootstrap";
import Geosuggest from "@ubilabs/react-geosuggest";
import styles from "pages/settings/GoodLooks.module.css"
import { Fragment, useEffect, useRef, useState } from "react";
import { useAppSelector } from "hooks";

interface ZoneModalPropsI {
    closeModal: () => void;
    okHandler: (object:any) => void;
    visible: boolean;
}

const COUNTRY_MX = "MX";
const ZoneModal = ({closeModal, okHandler, visible}: ZoneModalPropsI) => {
    const inputRef = useRef<Geosuggest>(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [name, setName] = useState("");
    const [factor, setFactor] = useState("");
    const [viatic, setViatic] = useState("0");
    const [zoneSelected, setZoneSelected] = useState<any>(undefined);
    const [address, setAddress] = useState<any>(undefined);

    const { countries } = useAppSelector(({ catalogs }) => catalogs);
    const { data } = countries;
    
    const getProperty = (prop:string, shortName=false) => {
        return address.find((item:any) => item.types.includes(prop))?.[shortName ? "short_name" : "long_name"] || "";
    }

    const handleClearState = () => {
        setName("");
        setFactor("");
        setZoneSelected(undefined);
        setAddress(undefined);
    }

    const handleHideModal = () => {
        handleClearState();
        closeModal();
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        okHandler({
            factor,
            travelers: viatic,
            administrative_area_level_1: getProperty("administrative_area_level_1") || "",
            administrative_area_level_2: getProperty("administrative_area_level_2") || "",
            locality: getProperty("locality"),
            neighborhood: getProperty("country", true) === COUNTRY_MX ? getProperty("sublocality_level_1") : getProperty("sublocality"),
            sublocality: "",
            country_id: data.find((item:any) => item.name.toLowerCase() === getProperty("country").toLowerCase())?.id
        });
    }

    const handleSearch = (value:any) => {
        setName(value);
        setZoneSelected(undefined);
        setAddress(undefined);
    }

    const formValidation = () => {
        if (zoneSelected && factor && viatic) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }

    useEffect(() => {
        if (zoneSelected) {
            const { gmaps } = zoneSelected;
            setName("");
            setAddress(gmaps.address_components);
        }
        formValidation();
    }, [name, zoneSelected, factor])

    return (
        <Modal show={visible} centered size="xl"
            onHide={handleHideModal} backdrop="static" keyboard={false}
        >
            <M.Header closeButton>
                <h6 className="m-0">{"Agregar Zona"}</h6>
            </M.Header>
            <M.Body>
                <h6>Ingresa una dirección y selecciona la que buscas:</h6>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Geosuggest
                                ref={inputRef}
                                inputType="search"
                                inputClassName="ant-input"
                                suggestItemClassName={styles["list-google"]}
                                types={["geocode"]}
                                placeholder="Ingresa la Zona"
                                onSuggestSelect={(suggest:any) => {
                                    setZoneSelected(suggest);
                                }}
                                renderSuggestItem={(suggest) => {
                                    const { description } = suggest;
                                    return (
                                        <Fragment>
                                                {name &&
                                                <div>
                                                    <p className="m-0">Dirección:</p>
                                                    <p className="m-0">{description}</p>
                                                    <Divider style={{ margin: "8px 0"}} />
                                                </div>}
                                        </Fragment>
                                    )
                                }}
                                // value={zoneSelected}
                                onChange={handleSearch}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            {address && 
                                <Fragment>
                                    <h6>Zona Seleccionada</h6>
                                    <p>{`Calle: ${getProperty("route")} Número: ${getProperty("street_number")}`}</p>
                                    <p>{`Colonia: ${getProperty("sublocality_level_1")}`}</p>
                                    <p>{`Código Postal: ${getProperty("postal_code")}`}</p>
                                    <p>{`Ciudad: ${getProperty("locality")}`}</p>
                                    <p>{`Estado: ${getProperty("administrative_area_level_1")}`}</p>
                                    <p>{`País: ${getProperty("country")}`}</p>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <InputNumber
                                                label="Factor"
                                                value={factor}
                                                min={0}
                                                onChange={(value:any) => setFactor(value)}
                                                classNameLabel="w-100"
                                                style={{ width: 250}}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <InputNumber
                                                label="Viaticos"
                                                value={viatic}
                                                min={0}
                                                onChange={(value:any) => setViatic(value)}
                                                classNameLabel="w-100"
                                                style={{ width: 250}}
                                            />
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                        </Col>
                    </Row>
                    <Divider style={{ margin: "16px 0"}} />
                    <Row>
                        <Col className={styles["col-form"]} xs={12} sm={12} md={{ offset: 4, span: 8}} lg={{ offset: 6, span: 6}}>
                            <Button size="sm" className="me-3 pry-outline-btn" onClick={handleHideModal}>
                                {"Cancelar"}
                            </Button>
                            <Button size="sm" className="primary-btn" type="submit" disabled={isDisabled}>
                                {"Enviar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </M.Body>
        </Modal>
    )
}
export default ZoneModal;
