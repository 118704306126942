import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { FLUSH,
   REHYDRATE,
   PAUSE,
   PERSIST,
   PURGE,
   REGISTER,
   persistStore, 
} from 'redux-persist';
import { Interceptor } from 'api/api';
import { userSlice } from './user';
import { serviceSlice } from './service';
import { huntersSlice } from './hunters';
import { catalogSlice } from './catalogs';

const rootReducer = combineReducers({
   catalogs: catalogSlice.reducer,
   services: serviceSlice.reducer,
   hunters: huntersSlice.reducer,
   user: userSlice.reducer,
});

const rootPersistConfig = {
   key: 'root',
   storage,
   whitelist: ['user', 'services', 'catalogs']
}

const persistedReducer = persistReducer<any, Action<any>>(rootPersistConfig, rootReducer);

export const store = configureStore({
   reducer: persistedReducer,
   devTools: process.env.NODE_ENV !== 'production',
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
         }
      })
})

Interceptor(store);
// persisted constant
export const persistor = persistStore(store);
// infer the RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;