import { Empty, notification } from "antd";
import esES from 'antd/lib/locale/es_ES';
import { postEvents, putEvent } from "api";
import { CommonSearchForm, InternalPageLayout, Table } from "components";
import { useAppSelector } from "hooks";
import { AddEventModal } from "pages/codes/events";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { cleanCurrentEvent } from "store/hunters";
import { setEvents, setHunters } from "store/hunters/hunters_t";
import { eventColumns } from "utils/tableColumns";

const Events = () => {
    const [search, setSearch] = useState("");
    const [modalShowed, setModalShowed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { event, promoCodes } = useAppSelector(({ hunters }) => hunters);
    const { data } = promoCodes;
    const dispatch = useDispatch();

    const filterEvents = () => {
        if (data) {
            return data.filter((item:any) => {
                const { code, description, hunter } = item;
                const tempName = hunter ? `${hunter.name}${hunter.lastnames ? ` ${hunter.lastnames}`  : ""}`.toLowerCase() : "null";
                const tempCode = code ? code.toLowerCase() : '';
                const tempLDescription = description ? description.toLowerCase() : '';
                return tempCode.match(search.toLowerCase()) || tempLDescription.match(search.toLowerCase()) || tempName.match(search.toLowerCase());
            });
        }
    }

    const registerEvent = async (body:any) => {
        try {
            setIsLoading(true);
            const { success, errors } = Object.keys(event).length > 0 ? await putEvent(event.id, body)
            : await postEvents(body);
            if (success === "true") {
                dispatch(setEvents());
                dispatch(cleanCurrentEvent());
                onCloseModal();
                notification.success({
                    message: `${Object.keys(event).length > 0 ? "Edición" : "Registro"} de Evento`,
                    description: "Operación Exitosa"
                });
            } else {
                notification.error({
                    message: `${Object.keys(event).length > 0 ? "Edición" : "Registro"} de Evento`,
                    description: `${errors}`
                });
            }
            setIsLoading(false);
        } catch (error:any) {
            setIsLoading(false);
            notification.error({
                message: `${Object.keys(event).length > 0 ? "Edición" : "Registro"} de Evento`,
                description: `${error.message}`
            });
        }
    }

    const onCloseModal = () => {
        setModalShowed(false);
        dispatch(cleanCurrentEvent());
    }

    useEffect(() => {
        dispatch(setEvents());
        dispatch(setHunters());
    },[])

    return (
        <InternalPageLayout title="Eventos">
            <CommonSearchForm
                clickAdd={() => setModalShowed(true)}
                search={search}
                onSearch={({ target }: any) => setSearch(target.value.trimStart())}
            />
            <Table
                localeProv={esES}
                size="small"
                columns={eventColumns(dispatch, () => setModalShowed(true))}
                dataSource={search ? filterEvents() : promoCodes.data ? promoCodes.data : []}
                locale={{
                    triggerDesc: 'Ordenar de forma descendente',
                    triggerAsc: 'Ordenar de forma ascendente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No hay datos"
                    />)
                }}
                rowKey={'id'}
                scroll={{ x: 'auto' }}
                pagination={{ defaultPageSize: 10 }}
            />
            {modalShowed &&
            <AddEventModal
                okHandler={registerEvent}
                visible={modalShowed}
                closeModal={onCloseModal}
                loading={isLoading}
            />}
        </InternalPageLayout>
    )
}
export default Events;
