import { Select as AntdSelect, SelectProps } from "antd";
import { Form } from "react-bootstrap";

const { Option } = AntdSelect
interface SelectPropsI extends SelectProps {
    options: Array<{ value: string, label: string }>;
    label?: string | JSX.Element | JSX.Element[];
    classNameLabel?: string;
    text?: string;
    classNameText?: string;
    controlId?: string;
    classNameContainer?: string;
}

const Select = ({ options, label, classNameLabel, text,
    classNameText, controlId, classNameContainer, ...props }:SelectPropsI) => {

    return (
        <Form.Group controlId={controlId} className={classNameContainer}>
            <Form.Label className={classNameLabel}>
            {label}
            </Form.Label>
            <AntdSelect {...props}>
                {options.map((item, index) => (
                    <Option key={index} value={item.value}>{item.label}</Option>
                ))}
            </AntdSelect>
            <Form.Text className={classNameText}>
            {text}
            </Form.Text>
        </Form.Group>
    )
}
export default Select;