import dayjs from "dayjs";
import { Fragment, memo, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getCustomBadge } from "utils/getComponents";
import { EditOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select, TextArea, TimePicker } from "components";
import { statusEnum } from "utils/enums";

type DataT = {
    date: string;
    hour: string;
    items: Array<{
        id: number;
        price: number | string;
        product: {
            category_id: number;
            description: string;
            duration: number | string;
            id: number;
            name: string;
            order: number;
            photo: string;
            sell_price: number;
            status: boolean;
        };
        quantity: number; 
    }>;
    promoCode?: string;
    status?: string;
}

interface AddressDetailPropsI {
    data: DataT;
    sendingData?: (obj:any) => void;
    loading?: boolean;
}

const FULL_FORMAT = "MMM DD, YYYY hh:mm A";
const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
const WhenServiceDetail = ({ data, sendingData, loading }:AddressDetailPropsI) => {
    const { date, hour, items, promoCode, status } = data;
    const [statusText, setStatusText] = useState("");
    const [observations, setObservations] = useState("");
    const [dateF, setDateF] = useState(dayjs());
    const [isEdit, setIsEdit] = useState(false);
    const [isModified, setIsModified] = useState(false);

    const handleEdit = () => {
        setIsEdit(!isEdit);
    }

    const handleChange = (value:string) => {
        setStatusText(value);
        setIsModified(true);
    }

    const handleTextArea = ({target}:any) => {
        const { value } = target;
        setObservations(value);
        setIsModified(true);
    }

    const handleDateTimePicker = (e:any) => {
        setDateF(e);
        setIsModified(true);
    }

    const handleClick = (e:any) => {
        e.preventDefault();
        const body = {
            date: dayjs(dateF).format(DATE_FORMAT),
            status: statusText
        }
        if(sendingData) sendingData(body);
    }

    useEffect(() => {
        if (status) {
            setStatusText(status);
        }

        if (date && hour) {
            setDateF(dayjs(`${date} ${hour}`, FULL_FORMAT));
        }
    }, [status, date, hour])

    useEffect(() => {
        if (!isEdit && status) {
            setStatusText(status);
            setObservations("");
            setIsModified(false);
        }

        if (!isEdit && date && hour) {
            setDateF(dayjs(`${date} ${hour}`, FULL_FORMAT));
        }
    }, [isEdit])

    return (
        <Fragment>
            <Row>
                <Col className='text-end' xs={{ offset: 8, span: 4 }} sm={{ offset: 8, span: 4 }} md={{ offset: 7, span: 5 }} lg={{ offset: 6, span: 6 }}>
                    <p onClick={handleEdit} className='edit-p'>{isEdit ? 'Ver' : 'Editar'}<span><EditOutlined /></span></p>
                </Col>
            </Row>
            <Row>
                <Col className={"mb-2"} xs={12} sm={12} md={6} lg={4}>
                    <h6>{"¿Cuando?"}</h6>
                    {!isEdit &&
                    <Fragment>
                        <p className="text-capitalize m-0">Fecha: {date}</p>
                        <p>Hora: {hour}</p>
                    </Fragment>}
                    {isEdit &&
                    <Fragment>
                        <DatePicker
                            classNameLabel="w-100"
                            label="Fecha"
                            onChange={handleDateTimePicker}
                            style={{ width: 200, textTransform: 'uppercase' }}
                            value={dateF}
                            format={'DD/MMM/YYYY'}
                        />
                        <TimePicker
                            classNameLabel="w-100"
                            label="Hora"
                            onChange={handleDateTimePicker}
                            style={{ width: 200 }}
                            use12Hours
                            value={dateF}
                            format={"hh:mm A"}
                        />
                    </Fragment>
                    }
                </Col>
                <Col className="mb-2" xs={12} sm={12} md={12} lg={4}>
                    <h6>{"Estatus"}</h6>
                    {!isEdit && <p className="text-capitalize m-0">{"Estatus: "}{getCustomBadge(status || "")}</p>}
                    {isEdit &&
                    <Fragment>
                        <Select 
                            label="Estatus"
                            classNameLabel="w-100"
                            style={{ width: 200 }}
                            options={statusEnum}
                            defaultValue={status}
                            value={statusText}
                            onChange={handleChange}
                        />
                        <TextArea
                            classNameLabel="w-100"
                            label="Observaciones"
                            style={{ width: 200 }}
                            value={observations}
                            onChange={handleTextArea}
                        />
                    </Fragment>
                    }
                </Col>
                <Col className="mb-2" xs={12} sm={12} md={6} lg={4}>
                    <h6>{items.length < 2 ? "Servicio" : "Servicios"}</h6>
                    <ul>
                        {items.map((i, id) => <li key={id} className="m-0">{`${i.quantity} ${i.product.name}`}</li>)}
                    </ul>
                    <h6 className="mt-2">Código</h6>
                    <p>{promoCode || ""}</p>
                </Col>
            </Row>
            {isEdit &&
            <Row>
                <Col className="text-center p-2" xs={12} sm={12} md={12} lg={12}>
                    <Button className="primary-btn" disabled={!isModified} onClick={!loading ? handleClick : undefined} loading={loading}>
                        {"Guardar Cambios"}
                    </Button>
                </Col>
            </Row>}
        </Fragment>
    )
}
export default memo(WhenServiceDetail);
