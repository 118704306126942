import { Form } from "react-bootstrap";
import AntdTimePicker from "./AntdTimePicker";
import locals from 'antd/es/date-picker/locale/es_ES';

interface TimePickerPropsI {
    label?: string;
    classNameLabel?: string;
    text?: string;
    classNameText?: string;
    controlId?: string;
    classNameContainer?: string;
    onChange?: any;
    value?: any;
    defaultValue?: any;
    style?:any;
    placeholder?: string;
    format?: string;
    showNow?: boolean;
    locale?: any;
    use12Hours?: boolean;
}

const TimePicker = ({
    onChange, value, defaultValue, style,
    placeholder="Seleccionar", format="HH:mm", showNow=false, label, classNameLabel, text,
    classNameText, controlId, classNameContainer, locale=locals, use12Hours=false
 }: TimePickerPropsI) => {
    return (
        <Form.Group controlId={controlId} className={classNameContainer}>
            <Form.Label className={classNameLabel}>
                {label}
            </Form.Label>
            <AntdTimePicker
                locale={locale}
                style={style} 
                placeholder={placeholder}
                format={format}
                onChange={onChange}
                value={value}
                defaultValue={defaultValue}
                showNow={showNow}
                use12Hours={use12Hours}
            />
            <Form.Text className={classNameText}>
                {text}
            </Form.Text>
        </Form.Group>
    )
}
export default TimePicker;
