import { Empty, notification } from "antd";
import esES from 'antd/lib/locale/es_ES';
import { postCategory, putCategory } from "api";
import { CommonSearchForm, InternalPageLayout, Table } from "components";
import { useAppSelector } from "hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { categoryColumns } from "utils/tableColumns";
import CategoryFormModal from "./CategoryFormModal";
import { cleanCurrentCategory } from "store/catalogs";
import { setCategories } from "store/catalogs/catalogs_t";

const Countries = () => {
    const [modalShowed, setModalShowed] = useState(false);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const { categories, category } = useAppSelector(({ catalogs }) => catalogs);
    const { data } = categories;

    const filterCategories = () => {
        if (data) {
            return data.filter((item:any) => {
                const { name, description } = item;
                const tempName = name ? name.toLowerCase() : "";
                const tempDescription = description ? description.toLowerCase() : "";
                return tempName.match(search.toLowerCase()) || tempDescription.match(search.toLowerCase());
            });
        }
    }

    const onCloseModal = () => {
        setModalShowed(false);
        dispatch(cleanCurrentCategory());
    }

    const registerCategory = async (body:any) => {
        try {
            const { success, errors } = Object.keys(category).length > 0 ? await putCategory( category.id, {
                category: {
                    ...body.category,
                    order: category.order
                }}) : await postCategory({
                category: {
                    ...body.category,
                    order: data.length + 1
                }});
            if (success === "true") {
                dispatch(cleanCurrentCategory());
                dispatch(setCategories());
                onCloseModal();
                notification.success({
                    message: `${Object.keys(category).length > 0 ? "Edición" : "Registro"} de Categoría`,
                    description: `${Object.keys(category).length > 0 ? "La Edición" : "El Registro"} se realizó de forma exitosa`
                });
            } else {
                notification.error({
                    message: `${Object.keys(category).length > 0 ? "Edición" : "Registro"} de Categoría`,
                    description: errors
                });
            }
        } catch (error:any) {
            notification.error({
                message: `${Object.keys(category).length > 0 ? "Edición" : "Registro"} de Categoría`,
                description: error.message
            });
        }
    }

    return (
        <InternalPageLayout title="Categorías" >
            <CommonSearchForm
                clickAdd={() => setModalShowed(true)}
                search={search}
                onSearch={({ target }: any) => setSearch(target.value.trimStart())}
            />
            <Table
                localeProv={esES}
                columns={categoryColumns(dispatch, () => setModalShowed(true))}
                dataSource={search ? filterCategories() : data || []}
                size="small"
                rowKey={'id'}
                locale={{
                    triggerDesc: 'Ordenar de forma descendente',
                    triggerAsc: 'Ordenar de forma ascendente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No hay datos"
                    />
                    )
                }}
                scroll={{ x: 'auto' }}
                pagination={{ defaultPageSize: 10 }}
            />
            {modalShowed &&
            <CategoryFormModal
                okHandler={registerCategory}
                visible={modalShowed}
                closeModal={onCloseModal}
            />}
        </InternalPageLayout>
    )
}
export default Countries;
