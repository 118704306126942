import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'store/globalactions';
import { UserSchema } from 'store/schema';

export const userSlice = createSlice({
   name: 'USER',
   initialState: UserSchema,
   reducers: {
      userIsFetching: (state) => {
         state.data = UserSchema.data;
         state.errorMessage = '';
         state.status.fetching = true;
         state.status.state = 'FETCHING';
      },
      setUser: (state, { payload }) => {
         state.data = payload.state ? payload.data : UserSchema.data;
         state.errorMessage = payload.state ? '' : payload.data;
         state.status.fetching = false;
         state.status.state = payload.state ? 'SUCCESS' : 'ERROR';
      }
   },
   extraReducers: (builder) => {
      builder.addCase(logout, () => {
         return UserSchema;
      });
   },
});

export const { userIsFetching, setUser } = userSlice.actions;
