import { useState } from 'react';
import { uploadFile } from 'react-s3';

window.Buffer = window.Buffer || require("buffer").Buffer;

const useS3Upload = () => {
    const [isLoading, setIsLoading] = useState(false);

    const uploadingFile = async (file, config) => {
        try {
            let body = {
                success: false,
                location: "",
                error: ""
            };
            setIsLoading(true);
            const { result, location } = await uploadFile(file, config);
            if (result.ok) {
                body = { success: true, location, error: "" };
            } else {
                body = { success: false, location: "", error: "Error en bucket S3" }; 
            }
            setIsLoading(false);
            return body;
        } catch (error) {
            setIsLoading(false);
            return { success: false, location: "", error: `Error en bucket S3: ${error.message || error.statusText}` };
        }
    }

    return {
        uploadingFile,
        isLoading
    }
}
export default useS3Upload;
