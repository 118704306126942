import { Empty, notification, Spin } from "antd";
import esES from 'antd/lib/locale/es_ES';
import { CommonSearchForm, InternalPageLayout, Table } from "components";
import { useEffect, useState } from "react";
import { hunterColumns } from "utils/tableColumns";
import { AddHuntersModal } from "pages/codes/hunters";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks";
import { postHunter, putHunter } from "api";
import { setHunters } from "store/hunters/hunters_t";
import { cleanCurrentHunter } from "store/hunters";

const Hunters = () => {
    // redux constants
    const dispatch = useDispatch();
    const { hunters, hunter } = useAppSelector(({hunters}) => hunters);
    const {data, status} = hunters;
    // state components
    const [search, setSearch] = useState("");
    const [modalShowed, setModalShowed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const registerHunter = async (body:any) => {
        try {
            setIsLoading(true);
            const { success, errors } = Object.keys(hunter).length > 0 ?
            await putHunter(hunter.id, body)
            :
            await postHunter(body);
            if (success === "true") {
                dispatch(setHunters());
                dispatch(cleanCurrentHunter());
                onCloseModal();
                notification.success({
                    message: `${Object.keys(hunter).length > 0 ? "Actualización" : "Registro"} de Hunter`,
                    description: "Operación Exitosa"
                });
            } else {
                notification.error({
                    message: `${Object.keys(hunter).length > 0 ? "Actualización" : "Registro"} de Hunter`,
                    description: `${errors}`
                });
            }
            setIsLoading(false);
        } catch (error:any) {
            notification.error({
                message: `${Object.keys(hunter).length > 0 ? "Actualización" : "Registro"} de Hunter`,
                description: `${error.message}`
            });
        }
    }

    const onCloseModal = () => {
        setModalShowed(false);
        dispatch(cleanCurrentHunter());
    }

    const filterHunters = () => {
        if (data) {
            return data.filter((item:any) => {
                const { name, lastnames, phone, email } = item;
                const tempName = name ? name.toLowerCase() : '';
                const tempLName = lastnames ? lastnames.toLowerCase() : '';
                const tempPhone = phone ? phone.replace(/ /g, "") : '';
                const tempEmail = email ? email.toLowerCase() : '';
                return tempName.match(search.toLowerCase()) || tempLName.match(search.toLowerCase()) || tempPhone.match(search.replace(/ /g, "")) || tempEmail.match(search.toLowerCase())
            });
        }
    }

    useEffect(() => {
        dispatch(setHunters());
    }, [])

    return (
        <InternalPageLayout title="Hunters">
            <Spin spinning={status.fetching}>
                <CommonSearchForm
                    clickAdd={() => setModalShowed(true)}
                    search={search}
                    onSearch={({target}: any) => setSearch(target.value.trimStart())}
                />
                <Table
                    localeProv={esES}
                    size="small"
                    rowKey={'id'}
                    columns={hunterColumns(dispatch, () => setModalShowed(true))}
                    dataSource={search ? filterHunters() : data}
                    locale={{
                        triggerDesc: 'Ordenar de forma descendente',
                        triggerAsc: 'Ordenar de forma ascendente',
                        cancelSort: 'Cancelar ordenamiento',
                        emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No hay datos"
                        />
                        )
                    }}
                    scroll={{ x: 'auto' }}
                    pagination={{ defaultPageSize: 10 }}
                />
            </Spin>
            {modalShowed &&
            <AddHuntersModal
                okHandler={registerHunter}
                visible={modalShowed}
                closeModal={onCloseModal}
                loading={isLoading}
            />}
        </InternalPageLayout>
    )
}
export default Hunters;
