import api from "./api";

export interface SignInRequestI {
    email: string;
    password: string;
}

interface BaseResponseI {
    errors: any;
    success: any;
    status?: any;
}

export interface SignInResponseI {
    data?: {
        email: string;
        id: string;
        lastnames: string;
        name: string;
        photo_uri: string;
        profile: string;
        token: string;
        zones: Array<any>;
    } | any;
}

export const signIn = async (body:SignInRequestI) => {
    try {
        const { data }:SignInResponseI & BaseResponseI = await api.post("/es/v1/sign_in", { admin: body });
        return data;
    } catch (error:any) {
        if (error?.response) {
            return {
                data: {},
                errors: error?.response?.data?.errors[0],
                status: error?.response?.status,
                success: "false"
            }
        }
        return {
            data: {},
            errors: error.message,
            status: 500,
            success: "false"
        }
    }
}