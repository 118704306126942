import { Card as AntCard, CardProps } from "antd";

interface CardPropsI extends CardProps {
    // children:any;
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}
const Card = ({ children, ...rest }: CardPropsI) => {
    return (
        <AntCard {...rest}>
            {children}
        </AntCard>
    )
}
export default Card;