import { Image as AntdImage, ImageProps } from "antd";

interface ImagePropsI extends ImageProps {
    image: any;
}

export default function Image ({image, ...rest}: ImagePropsI) {
    return (
        <AntdImage src={image} {...rest} />
    )
}
