import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "components";
import { Col, Form, Row } from "react-bootstrap";

interface CommonFormPropsI {
    clickAdd: (e?:any) => void;
    search?: string;
    onSearch?: (e:any) => void;
    sndBtnText?: string;
    sndButtonOnClick?: (e?:any) => void;
    isSndButtonVisible?: boolean;
}

const CommonSearchForm = ({clickAdd, onSearch, search, sndBtnText="Enviar", sndButtonOnClick, isSndButtonVisible=false}: CommonFormPropsI) => {
    return (
        <Form as={Row} className="mb-2">
            <Col className="mt-1" sm={12} md={6} xl={5}>
                <Input
                    placeholder="Buscar"
                    prefix={<SearchOutlined />}
                    value={search}
                    onChange={onSearch}
                    allowClear
                />
            </Col>
            <Col className="mt-1" style={{ textAlign: "right" }} sm={12} md={6} xl={7}>
                {isSndButtonVisible &&
                <Button
                    className="pry-outline-btn me-1"
                    size="sm"
                    onClick={sndButtonOnClick}
                >
                    {sndBtnText}
                </Button>}
                <Button
                    className="primary-btn m-0"
                    size="sm"
                    onClick={clickAdd}
                >
                    {"Agregar"}
                </Button>
            </Col>
        </Form>
    )
}
export default CommonSearchForm;