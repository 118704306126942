import { UserOutlined } from "@ant-design/icons";
import { FC, ReactNode, useEffect, useState } from "react";

interface AvatarPropsI {
    shape?: "circle" | "square";
    size?: "default" | "small" | "large" | "xlarge";
    icon?: ReactNode | undefined;
    name?: string;
    color?: string;
    backgroundColor?: string;
    nameStyle?: any;
    nameClass?: string;
    margin?: any;
} 

const Avatar:FC<AvatarPropsI> = ({ shape="circle", size="default", icon, name, color="#FFFFFF", backgroundColor="gray",
    nameStyle, nameClass, margin }) => {
    const [tempName, setTempName] = useState("");

    useEffect(() => {
        const temp = name ? name.split(" ") : "";
        if (temp) {
            if (temp.length > 1) {
                const tempFst = temp[0].toUpperCase().split('');
                const tempSnd = temp[1].toUpperCase().split('');
                setTempName(`${tempFst[0]}${tempSnd[0]}`);
            } else {
                const tempFst = temp[0].toUpperCase().split('');
                setTempName(tempFst[0]);
            }
        }
    }, [name])

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor,
            color,
            width: size === "default" ? 32 : size === "large" ? 45 : size === "small" ? 25 : 80,
            height: size === "default" ? 32 : size === "large" ? 45 : size === "small" ? 25 : 80,
            borderRadius: shape === "square" ? 5 : "50%",
            margin: margin ? margin : 5
        }}
        >
            {name ?
                <p className={`m-0 ${nameClass}`} style={nameStyle}>{tempName}</p>
                :
                icon ?
                    icon
                    :
                    <UserOutlined style={{ fontSize: size === "large" ? "25px" : size === "default" ? "18px" : size === "small" ? "15px" : "30px" }} />}
        </div>
    )
}
export default Avatar;