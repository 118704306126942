import { Empty, notification, Row } from "antd";
import esES from 'antd/lib/locale/es_ES';
import { getTodayServices } from "api";
import { Button, InternalPageLayout, Table } from "components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cleanCurrentService } from "store/service";
import { dayServicesColumns } from "utils/tableColumns";
import AddServiceForm from "./AddServiceForm";

const DayServices = () => {
    const [services, setServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddService, setShowAddService] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const gettingServices = async () => {
        if (!isLoading)
            setIsLoading(true);
        try {
            const {data, errors} = await getTodayServices();
            if (data) {
                setServices(data);
            } else {
                setServices([]);
                notification.error({
                    message: "Listado de Servicios",
                    description: errors
                });
            }
            setIsLoading(false);
        } catch (error:any) {
            setServices([]);
            setIsLoading(false);
            notification.error({
                message: "Listado de Servicios",
                description: error.message
            });
        }
    }

    useEffect(() => {
        gettingServices();
        dispatch(cleanCurrentService());
    }, [])

    return (
        <InternalPageLayout title="Servicios del Día">

            <Row style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}} >
                {
                    !showAddService &&
                    //     <Button
                    //         className="primary-btn m-0"
                    //         size="sm"
                    //         onClick={()=> setShowAddService(false)}
                    //     >
                    //         {"Ir a Servicios del día"}
                    //     </Button>
                    // :
                    <Button
                        className="primary-btn m-0"
                        size="sm"
                        onClick={()=> setShowAddService(true)}
                    >
                        {"Agregar"}
                    </Button>
                }
            </Row>
            {
                !showAddService ?
                    <Table
                        localeProv={esES}
                        columns={dayServicesColumns(navigate, dispatch)}
                        dataSource={services}
                        size="small"
                        rowKey={'id'}
                        // dataSource={search ? filterHunters() : hunters}
                        locale={{
                            triggerDesc: 'Ordenar de forma descendente',
                            triggerAsc: 'Ordenar de forma ascendente',
                            cancelSort: 'Cancelar ordenamiento',
                            emptyText: (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No hay datos"
                            />
                            )
                        }}
                        scroll={{ x: 'auto' }}
                        pagination={{ defaultPageSize: 10 }}
                        loading={isLoading}
                    />
                :
                <AddServiceForm/>
            }
        </InternalPageLayout>
    )
}
export default DayServices;