function useValidation () {
    const curpValidation = (value: string) => {
        const regex = /^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$/
        return !!regex.test(value)
    }
    const emailValidation = (value: string) => {
        const regex = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,63}$/
        return !!regex.test(value)
    }

    const numberValidation = (value: string) => {
        const regex = /^\d+$/
        return !!regex.test(value)
    }
     
    const rfcValidation = (value: string) => {
        const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
        return !!regex.test(value)
    }

    const wordValidation = (value: string) => {
        const regex = /^[a-zA-Z ¨´áéíóúñüÁÉÍÓÚÑÜ]*$/
        return !!regex.test(value)
    }

    return {
        curpValidation,
        emailValidation,
        numberValidation,
        rfcValidation,
        wordValidation
    }
}
export default useValidation;
