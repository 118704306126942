import { notification, Spin } from "antd";
import { getEmployees, postEmployee, putEmployee } from "api";
import { Button, InternalPageLayout, TabPane, Tabs } from "components";
import { useValidation } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {ServicesForm, UserForm, ZonesForm } from "pages/settings/goodlookero";
import styles from "pages/settings/GoodLooks.module.css";

const PHONE_LENGTH = 10;
const MINIMUM_PASS_CHAR = 4;
const ManipulateGoodLook = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [request, setRequest] = useState<any>({});
    const [employee, setEmployee] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const isEdit = id ? true : false;
    const { emailValidation } = useValidation();

    const gettingEmployee = async () => {
        try {
            if (!isLoading) setIsLoading(true);
            const { data, success, errors } = await getEmployees(id);
            if (success === "true") {
                setEmployee(data);
            } else {
                setEmployee(undefined);
                notification.error({
                    message: "Información de GoodLookero",
                    description: errors
                });
            }
            setIsLoading(false);
        } catch (error:any) {
            setEmployee(undefined);
            setIsLoading(false);
            notification.error({
                message: "Información de GoodLookero",
                description: `Ocurrio un Error. ${error.message}`
            });
        }
    }

    const handleSubmit = async (body:any) => {
        try {
            delete body.confPass;
            const response = id ? await putEmployee(id, { employee: body }) : await postEmployee({ employee: body });
            if (response.success === "true") {
                setIsLoading(false);
                notification.success({
                    message: "GoodLookero",
                    description: "Operación Exitosa"
                });
                navigate("/goodlooks", { replace: true });
            } else {
                notification.error({
                    message: "GoodLookero",
                    description: `Ocurrio un Error. ${response.errors}`
                });
                setIsLoading(false);
            }
        } catch (error:any) {
            setIsLoading(false);
            notification.error({
                message: "GoodLookero",
                description: `Ocurrio un Error. ${error.message}`
            });
        }
    }

    const handleRequest = (body:any) => {
        setRequest({
            ...request,
            ...body,
        })
    }

    const handleCancel = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (id) {
            gettingEmployee();
        }
    },[])

    useEffect(() => {
        if (employee) {
            setRequest({
                email: employee.email,
                gender: employee.gender,
                lastnames: employee.lastnames,
                name: employee.name,
                password: "",
                phone: employee.phone ? employee.phone.replace(/ |\(|\)/gi, "") : "",
                notes: employee.notes,
                products: employee.products || [],
                zones: employee.zones || [],
            })
        }
    }, [employee])

    useEffect(() => {
        if (Object.keys(request).length > 0 && request.name && request.lastnames && request.gender && emailValidation(request.email) &&
        request.phone.replace(/ /g, "").length === PHONE_LENGTH && request.password === request.confPass && request.password.length >= MINIMUM_PASS_CHAR) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [request])

    return (
        <InternalPageLayout title={`${isEdit ? "Editar" : "Agregar"} Goodlookero`} >
            <Spin spinning={isLoading}>
                {!isEdit && 
                    <UserForm
                        onSubmited={handleSubmit}
                        onCancel={handleCancel}
                        employee={employee}
                    />
                }
                {isEdit &&
                    <Fragment>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Información Personal" key="1">
                                <UserForm
                                    onSubmited={handleRequest}
                                    onCancel={handleCancel}
                                    employee={employee}
                                />
                            </TabPane>
                            <TabPane tab="Configuración Servicios" key="2">
                                <ServicesForm
                                    services={employee ? employee.products : null}
                                    setRequest={handleRequest}
                                />
                            </TabPane>
                            <TabPane tab="Configuración Zonas" key="3">
                                <ZonesForm
                                    zonesArr={employee ? employee.zones : null}
                                    setRequest={handleRequest}
                                />
                            </TabPane>
                        </Tabs>
                        <Row className="mt-2">
                            <Col className={styles["col-form"]} xs={12} sm={12} md={{ offset: 4, span: 8}} lg={{ offset: 6, span: 6}}>
                                <Button size="sm" className="me-3 pry-outline-btn" onClick={handleCancel}>
                                    {"Cancelar"}
                                </Button>
                                <Button size="sm" className="primary-btn" disabled={isDisabled} onClick={() => handleSubmit(request)}>
                                    {"Enviar"}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                }
            </Spin>
        </InternalPageLayout>
    )
}
export default ManipulateGoodLook;