import { Card, Container, Divider } from "components";
import React, { FC, Fragment } from "react"

interface InternalPageLaoutPropsI {
    title?: string;
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}

const InternalPageLayout:FC<InternalPageLaoutPropsI> = ({title, children}) => {
    return (
        <Container className="pt-3">
            <Card>
                {title && 
                    <Fragment>
                        <h3>{title}</h3>
                        <Divider style={{ margin: "16px 0"}} />
                    </Fragment>
                }
                {children}
            </Card>
        </Container>
    )
}
export default InternalPageLayout;