import { Empty, Transfer } from "antd";
import { Form } from "components";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";

interface AssignBannerPropsI {
    setRequest: (obj:any) => void;
}

const AssignBanner = ({setRequest}: AssignBannerPropsI) => {
    const [targetKeys, setTargetKeys] = useState([]);
    const { banners, zone } = useAppSelector(({ catalogs }) => catalogs);

    const renderItem = (item:any) => {
        return {
            label: <><img style={{width: "30%", marginRight: 5}} src={item.photo} alt={`banner-${item.key}`}/>{item.title}</>, // for displayed item
            value: item.key, // for title and filter matching
        };
    }

    const handleChange = (keys:any) => {
        setTargetKeys(keys);
    };

    useEffect(() => {
        if (zone && Object.keys(zone).length > 0) {
            setTargetKeys(zone.banners.map((item:any) => item.banner_id.toString()));
        }
    },[])

    useEffect(() => {
        if (targetKeys.length > 0) {
            const data:any = new Set(targetKeys); // filter duplicates
            const arrData = [...data]; // set into array
            setRequest({banners: arrData.map((item:any) => ({ banner_id: item }))}); // arr mapped into request
        } else {
            setRequest({banners: targetKeys});
        }
    }, [targetKeys])

    return (
        <Form>
            <Transfer
                titles={["Banners", "Banners Seleccionados"]}
                locale={{
                    itemUnit: "banners",
                    itemsUnit: "banners",
                    notFoundContent: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No hay datos"
                        />
                    )
                }}
                dataSource={banners.data ? banners.data.map((item:any) => ({
                    key: item.id.toString(),
                    title: item.name,
                    description: item.description,
                    photo: item.photo
                })) : []}
                listStyle={{
                    width: "45%",
                    height: 350,
                }}
                onChange={handleChange}
                render={renderItem}
                targetKeys={targetKeys}
            />
        </Form>
    )
}
export default AssignBanner;
