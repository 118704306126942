import { Badge } from "components";

interface CustomBadgeI {
    classN?: string;
    CustomTag?: "" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "fragment";
}

export const getCustomBadge = (text:string, maxSize=false) => {
    // const { CustomTag, classN } = body;
    switch (text) {
        case "arriving":
            return <div style={{ textAlign: 'center', width: maxSize ? '100%' : 'fit-content', height: maxSize ? '100%' : 'fit-content', backgroundColor: '#fff3cd', borderRadius: 5, padding: 3}}><p className='m-0' style={{ color: '#856404' }}>{"Llegando"}</p></div>;

            // return CustomTag ?
            //     CustomTag === "fragment" ? <><Badge bg="light" className={classN || ""} text="dark">{"Llegando"}</Badge></> : <CustomTag><Badge bg="light" className={classN || ""} text="dark">{"Llegando"}</Badge></CustomTag> 
            //     :
            //     <h6 className="m-0"><Badge className={classN || ""} bg="light" text="dark">{"Llegando"}</Badge></h6>;
        case "ongoing":
            return <div style={{ textAlign: 'center', width: maxSize ? '100%' : 'fit-content', height: maxSize ? '100%' : 'fit-content', backgroundColor: '#e2e3e5', borderRadius: 5, padding: 3}}><p className='m-0' style={{ color: '#383d41' }}>{"En Proceso"}</p></div>;
            // return CustomTag ?
            //     CustomTag === "fragment" ? <><Badge bg="warning" className={classN || ""}>{"En Proceso"}</Badge></> : <CustomTag><Badge bg="warning" className={classN || ""}>{"En Proceso"}</Badge></CustomTag>
            //     :
            //     <h6 className="m-0"><Badge className={classN || ""} bg="warning">{"En Proceso"}</Badge></h6>;
        case "canceledGood":
            return <div style={{ textAlign: 'center', width: maxSize ? '100%' : 'fit-content', height: maxSize ? '100%' : 'fit-content', backgroundColor: '#818182', borderRadius: 5, padding: 3}}><p className='m-0' style={{ color: '#fefefe' }}>{"Cancelado GL"}</p></div>;
            // return CustomTag ?
            //     CustomTag === "fragment" ? <><Badge bg="dark" className={classN || ""}>{"Cancelado Good"}</Badge></> : <CustomTag><Badge bg="dark" className={classN || ""}>{"Cancelado Good"}</Badge></CustomTag>
            //     :
            //     <h6 className="m-0"><Badge className={classN || ""} bg="dark">{"Cancelado Good"}</Badge></h6>;
        case "canceledUser":
            return <div style={{ textAlign: 'center', width: maxSize ? '100%' : 'fit-content', height: maxSize ? '100%' : 'fit-content', backgroundColor: '#f8d7da', borderRadius: 5, padding: 3}}><p className='m-0' style={{ color: '#721c24' }}>{"Cancelado Usuario"}</p></div>;
            // return CustomTag ?
            //     CustomTag === "fragment" ? <><Badge bg="dark" className={classN || ""}>{"Cancelado Usuario"}</Badge></> : <CustomTag><Badge bg="dark" className={classN || ""}>{"Cancelado Usuario"}</Badge></CustomTag>
            //     :
            //     <h6 className="m-0"><Badge className={classN || ""} bg="dark">{"Cancelado Usuario"}</Badge></h6>;
        case "finished":
            return <div style={{ textAlign: 'center', width: maxSize ? '100%' : 'fit-content', height: maxSize ? '100%' : 'fit-content', backgroundColor: '#d4edda', borderRadius: 5, padding: 3}}><p className='m-0' style={{ color: '#155724' }}>{"Finalizado"}</p></div>;
            // return CustomTag ?
            //     CustomTag === "fragment" ? <><Badge bg="info" className={classN || ""}>{"Finalizado"}</Badge></> : <CustomTag><Badge bg="info" className={classN || ""}>{"Finalizado"}</Badge></CustomTag>
            //     :
            //     <h6 className="m-0"><Badge className={classN || ""} bg="info">{"Finalizado"}</Badge></h6>;
        case "new":
            return <div style={{ textAlign: 'center', width: maxSize ? '100%' : 'fit-content', height: maxSize ? '100%' : 'fit-content', backgroundColor: '#d1ecf1', borderRadius: 5, padding: 3}}><p className='m-0' style={{ color: '#007bff' }}>{"Nuevo"}</p></div>;
            // return CustomTag ?
            //     CustomTag === "fragment" ? <><Badge bg="success" className={classN || ""}>{"Nuevo"}</Badge></> : <CustomTag><Badge bg="success" className={classN || ""}>{"Nuevo"}</Badge></CustomTag>
            //     :
            //     <h6 className="m-0"><Badge className={classN || ""} bg="success">{"Nuevo"}</Badge></h6>;
        case "active":
            return <div style={{ textAlign: 'center', width: maxSize ? '100%' : 'fit-content', height: maxSize ? '100%' : 'fit-content', backgroundColor: '#d4edda', borderRadius: 5, padding: 3}}><p className='m-0' style={{ color: '#155724' }}>{"Activo"}</p></div>;
            // return CustomTag ?
            //     CustomTag === "fragment" ? <><Badge bg="success" className={classN || ""}>{"Activo"}</Badge></> : <CustomTag><Badge bg="success" className={classN || ""}>{"Activo"}</Badge></CustomTag>
            //     :
            //     <h6 className="m-0"><Badge className={classN || ""} bg="success">{"Activo"}</Badge></h6>;
        case "inactive":
            return <div style={{ textAlign: 'center', width: maxSize ? '100%' : 'fit-content', height: maxSize ? '100%' : 'fit-content', backgroundColor: '#f8d7da', borderRadius: 5, padding: 3}}><p className='m-0' style={{ color: '#721c24' }}>{"Inactivo"}</p></div>;
            // return CustomTag ?
            //     CustomTag === "fragment" ? <><Badge bg="warning" className={classN || ""}>{"Inactivo"}</Badge></> : <CustomTag><Badge bg="warning" className={classN || ""}>{"Inactivo"}</Badge></CustomTag>
            //     :
            //     <h6 className="m-0"><Badge className={classN || ""} bg="warning">{"Inactivo"}</Badge></h6>;
        case "assigned":
        default:
            return <div style={{ textAlign: 'center', width: maxSize ? '100%' : 'fit-content', height: maxSize ? '100%' : 'fit-content', backgroundColor: '#cce5ff', borderRadius: 5, padding: 3}}><p className='m-0' style={{ color: '#004085' }}>{"Asignado"}</p></div>;
            // return CustomTag ?
            //     CustomTag === "fragment" ? <><Badge bg="primary" className={classN || ""}>{"Asignado"}</Badge></> : <CustomTag><Badge bg="primary" className={classN || ""}>{"Asignado"}</Badge></CustomTag>
            //     :
            //     <h6 className="m-0"><Badge className={classN || ""} bg="primary">{"Asignado"}</Badge></h6>;
    }
}

export const getCustomBadges = (text:string, body:CustomBadgeI={ CustomTag: "", classN: ""}) => {
    const { CustomTag, classN } = body;
    switch (text) {
        case "arriving":
            return CustomTag ?
                CustomTag === "fragment" ? <><Badge bg="light" className={classN || ""} text="dark">{"Llegando"}</Badge></> : <CustomTag><Badge bg="light" className={classN || ""} text="dark">{"Llegando"}</Badge></CustomTag> 
                :
                <h6 className="m-0"><Badge className={classN || ""} bg="light" text="dark">{"Llegando"}</Badge></h6>;
        case "ongoing":
            return CustomTag ?
                CustomTag === "fragment" ? <><Badge bg="warning" className={classN || ""}>{"En Proceso"}</Badge></> : <CustomTag><Badge bg="warning" className={classN || ""}>{"En Proceso"}</Badge></CustomTag>
                :
                <h6 className="m-0"><Badge className={classN || ""} bg="warning">{"En Proceso"}</Badge></h6>;
        case "canceledGood":
            return CustomTag ?
                CustomTag === "fragment" ? <><Badge bg="dark" className={classN || ""}>{"Cancelado Good"}</Badge></> : <CustomTag><Badge bg="dark" className={classN || ""}>{"Cancelado Good"}</Badge></CustomTag>
                :
                <h6 className="m-0"><Badge className={classN || ""} bg="dark">{"Cancelado Good"}</Badge></h6>;
        case "canceledUser":
            return CustomTag ?
                CustomTag === "fragment" ? <><Badge bg="dark" className={classN || ""}>{"Cancelado Usuario"}</Badge></> : <CustomTag><Badge bg="dark" className={classN || ""}>{"Cancelado Usuario"}</Badge></CustomTag>
                :
                <h6 className="m-0"><Badge className={classN || ""} bg="dark">{"Cancelado Usuario"}</Badge></h6>;
        case "finished":
            return CustomTag ?
                CustomTag === "fragment" ? <><Badge bg="info" className={classN || ""}>{"Finalizado"}</Badge></> : <CustomTag><Badge bg="info" className={classN || ""}>{"Finalizado"}</Badge></CustomTag>
                :
                <h6 className="m-0"><Badge className={classN || ""} bg="info">{"Finalizado"}</Badge></h6>;
        case "new":
            return CustomTag ?
                CustomTag === "fragment" ? <><Badge bg="success" className={classN || ""}>{"Nuevo"}</Badge></> : <CustomTag><Badge bg="success" className={classN || ""}>{"Nuevo"}</Badge></CustomTag>
                :
                <h6 className="m-0"><Badge className={classN || ""} bg="success">{"Nuevo"}</Badge></h6>;
        case "active":
            return CustomTag ?
                CustomTag === "fragment" ? <><Badge bg="success" className={classN || ""}>{"Activo"}</Badge></> : <CustomTag><Badge bg="success" className={classN || ""}>{"Activo"}</Badge></CustomTag>
                :
                <h6 className="m-0"><Badge className={classN || ""} bg="success">{"Activo"}</Badge></h6>;
        case "inactive":
            return CustomTag ?
                CustomTag === "fragment" ? <><Badge bg="warning" className={classN || ""}>{"Inactivo"}</Badge></> : <CustomTag><Badge bg="warning" className={classN || ""}>{"Inactivo"}</Badge></CustomTag>
                :
                <h6 className="m-0"><Badge className={classN || ""} bg="warning">{"Inactivo"}</Badge></h6>;
        case "assigned":
        default:
            return CustomTag ?
                CustomTag === "fragment" ? <><Badge bg="primary" className={classN || ""}>{"Asignado"}</Badge></> : <CustomTag><Badge bg="primary" className={classN || ""}>{"Asignado"}</Badge></CustomTag>
                :
                <h6 className="m-0"><Badge className={classN || ""} bg="primary">{"Asignado"}</Badge></h6>;
    }
}

export const getBooleanText = (text:boolean) => {
    if (text) {
        return 'Activo'
    }
    return 'Inactivo'
}