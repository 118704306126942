import { Table as AntdTable, TableProps, ConfigProvider } from "antd";
import dflt from "antd/lib/locale/en_US";

interface TablePropsI extends TableProps<any> {
    localeProv?:any;
}
const Table = ({ localeProv=dflt, ...rest }:TablePropsI) => {
    return (
        <ConfigProvider locale={localeProv}>
            <AntdTable {...rest} />
        </ConfigProvider>
    )
}
export default Table;
