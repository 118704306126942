import { FC } from "react";
import {Container as BootstrapContainer, ContainerProps } from "react-bootstrap";

interface ContProps extends ContainerProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}

const Container:FC<ContProps> = ({children, ...rest}) => {
    return (
        <BootstrapContainer {...rest}>
            {children}
        </BootstrapContainer>
    )
}
export default Container