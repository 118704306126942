import { Upload, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Avatar, Button } from 'components';

interface UploadPropsI extends UploadProps {
    label?: string | JSX.Element | JSX.Element[];
    classNameLabel?: string;
    text?: string;
    classNameText?: string;
    controlId?: string;
    classNameContainer?: string;
    image?: any;
    classNameImage?: string;
}

const AvatarUpload = ({ image, ...rest }: UploadPropsI) => {

    return (
        <ImgCrop>
            <Upload
                name="avatar"
                {...rest}
            >
                <div className="text-center" style={{ display: "flex", justifyContent: "center", marginBottom: 5 }}>
                    {image ? <img src={image} alt="avatar" style={{ width: 80, height: 80, backgroundSize: "auto", borderRadius: 80/2 }} /> : <Avatar size={"xlarge"} />}
                </div>
                <Button size="sm" className='pry-outline-btn'>{image ? "Modificar" : "Agregar"}</Button>
            </Upload>
        </ImgCrop>
    )
}
export default AvatarUpload;