import { Empty, notification } from "antd";
import esES from 'antd/lib/locale/es_ES';
import { postCountry, putCountry } from "api";
import { CommonSearchForm, InternalPageLayout, Table } from "components";
import { useAppSelector } from "hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { countryColumns } from "utils/tableColumns";
import CountryForm from "./CountryForm";
import { cleanCurrentCountry } from "store/catalogs";
import { setCountries } from "store/catalogs/catalogs_t";

const Countries = () => {
    const [modalShowed, setModalShowed] = useState(false);
    const [search, setSearch] = useState("");

    const dispatch = useDispatch();
    const { countries, country } = useAppSelector(({ catalogs }) => catalogs);
    const { data } = countries;

    const filterCountries = () => {
        if (data) {
            return data.filter((item:any) => {
                const { name } = item;
                const tempName = name ? name.toLowerCase() : "";
                return tempName.match(search.toLowerCase());
            });
        }
    }

    const onCloseModal = () => {
        setModalShowed(false);
        dispatch(cleanCurrentCountry());
    }

    const registerCountry = async (body:any) => {
        try {
            const { success, errors } = Object.keys(country).length > 0 ?
            await putCountry(country.id, body)
            :
            await postCountry(body);
            if (success === "true") {
                dispatch(cleanCurrentCountry());
                dispatch(setCountries());
                onCloseModal();
                notification.success({
                    message: `${Object.keys(country).length > 0 ? "Edición" : "Registro"} de País`,
                    description: `${Object.keys(country).length > 0 ? "La edición" : "El registro"} se realizó de forma exitosa`
                });
            } else {
                notification.error({
                    message: `${Object.keys(country).length > 0 ? "Edición" : "Registro"} de País`,
                    description: errors
                });
            }
        } catch (error:any) {
            notification.error({
                message: `${Object.keys(country).length > 0 ? "Edición" : "Registro"} de País`,
                description: error.message
            });
        }
    }

    return (
        <InternalPageLayout title="Países" >
            <CommonSearchForm
                clickAdd={() => setModalShowed(true)}
                search={search}
                onSearch={({ target }:any) => setSearch(target.value.trimStart())}
            />
            <Table
            localeProv={esES}
                columns={countryColumns(dispatch, () => setModalShowed(true))}
                dataSource={search ? filterCountries() : data || []}
                size="small"
                rowKey={'id'}
                locale={{
                    triggerDesc: 'Ordenar de forma descendente',
                    triggerAsc: 'Ordenar de forma ascendente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No hay datos"
                    />
                    )
                }}
                scroll={{ x: 'auto' }}
                pagination={{ defaultPageSize: 10 }}
            />
            {modalShowed &&
            <CountryForm
                okHandler={registerCountry}
                visible={modalShowed}
                closeModal={onCloseModal}
            />}
        </InternalPageLayout>
    )
}
export default Countries;
