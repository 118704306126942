import { Tabs, TabPaneProps } from 'antd';
import { FC } from 'react';

interface TPProps extends TabPaneProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}

const TabPane:FC<TPProps> = ({ children, ...props }) => {
    return (
        <Tabs.TabPane {...props}>
            {children}
        </Tabs.TabPane>
    )
}
export default TabPane