import { Layout as AntdLayout } from "antd";
// import Assets from "assets/Assets";
// import { Image } from "components";
import { Fragment } from "react";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

import styles from "./Layout.module.css";

const { Header } = AntdLayout;

interface LayoutPropsI {
    style?: any;
}
export default function Layout ({ style={} }:LayoutPropsI) {
    return (
        <Fragment>
            <Header className={styles["navbar"]}
                style={{
                    padding: "0 10px",
                    position: "sticky",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 1
                }}
            >
                <div style={{
                    width: 200,
                    textAlign: "center"
                }}
                >
                    <Navbar.Brand
                        as={Link}
                        to="/"
                        style={{ color: "var(--white-color)" }}
                    >
                        <b>Good</b>Look
                    </Navbar.Brand>
                </div>
            </Header>
            <Container fluid className={styles["layout-container"]}>
                <Row className={styles.container}>
                    {/* <Col className={styles["col-logo"]} sm={12} md={6} lg={6}>
                        <Image image={Assets.logo["goodlook"]} width={"calc(65%)"} preview={false} alt="gl-logo" />
                    </Col> */}
                    <Col className={styles["col-logo"]} sm={12} md={6} lg={6} />
                    <Col style={style} sm={12} md={6} lg={6}>
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
