import { Button, InternalPageLayout, TabPane, Tabs } from "components";
import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "pages/settings/GoodLooks.module.css";
import AssignBanner from "./AssignBanner";
import ZoneForm from "./ZoneForm";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";
import { getZones, putZone } from "api";
import { useNavigate, useParams } from "react-router-dom";
import { Empty, notification } from "antd";
import { cleanCurrentZone, setCurrentZone } from "store/catalogs";
import { setZones } from "store/catalogs/catalogs_t";

const EditZone = () => {
    const [zoneBody, setZoneBody] = useState({});
    const { id } = useParams();
    const { zone } = useAppSelector(({ catalogs }) => catalogs);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const setZoneObject = (data:any) => {
        setZoneBody({
            administrative_area_level_1: data.administrative_area_level_1,
            administrative_area_level_2: data.administrative_area_level_2,
            banners: data.banners,
            factor: data.factor,
            locality: data.locality,
            neighborhood: data.neighborhood,
            sublocality: data.sublocality,
            travelers: data.travelers
        });
    }
    
    const getZoneById = async () => {
        try {
            const { data, success, errors } = await getZones(id);
            if (success === "true") {
                dispatch(setCurrentZone(data));
                setZoneObject(data);
            } else {
                notification.error({
                    message: "Obtener Zona",
                    description: errors
                });
            }
        } catch (error:any) {
            notification.error({
                message: "Obtener Zona",
                description: error.message
            });
        }
    }

    const puttingZone = async (e:any) => {
        e.preventDefault();
        try {
            const { success, errors } = await putZone(id, { zone: zoneBody });
            if (success === "true") {
                dispatch(cleanCurrentZone());
                dispatch(setZones());
                notification.success({
                    message: "Actualizar Zona",
                    description: "Operación Exitosa"
                });
                navigate("/zones", { replace: true })
            } else {
                notification.error({
                    message: "Actualizar Zona",
                    description: errors
                });
            }
        } catch (error:any) {
            notification.error({
                message: "Actualizar Zona",
                description: error.message
            });
        }
    }

    useEffect(() => {
        if (id && Object.keys(zone).length === 0) {
            getZoneById();
        }
        if (zone && Object.keys(zone).length > 0) {
            setZoneObject(zone);
        }
    }, [])

    return (
        <InternalPageLayout title="Editar Zona">
            {Object.keys(zone).length > 0 &&
            <Fragment>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Información de Banner" key="1">
                        <ZoneForm setRequest={(body) => setZoneBody((prev:any) => ({ ...prev, ...body }))} />
                    </TabPane>
                    <TabPane tab="Asignar Banners" key="2">
                        <AssignBanner setRequest={(body) => setZoneBody((prev:any) => ({ ...prev, ...body }))} />
                    </TabPane>
                </Tabs>
                <Row className="mt-2">
                    <Col className={styles["col-form"]} xs={12} sm={12} md={{ offset: 4, span: 8}} lg={{ offset: 6, span: 6}}>
                        <Button size="sm" className="me-3 pry-outline-btn" onClick={() => {
                            dispatch(cleanCurrentZone());
                            navigate("/zones");
                        }}>
                            {"Cancelar"}
                        </Button>
                        <Button size="sm" className="primary-btn" onClick={puttingZone}>
                            {"Enviar"}
                        </Button>
                    </Col>
                </Row>
            </Fragment>}
            {Object.keys(zone).length === 0 &&
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No hay datos"
            />}
        </InternalPageLayout>
    )
}
export default EditZone;
