import { Input as FormInput, InputProps } from "antd";
import { Form } from "react-bootstrap";

interface InputPropsI extends InputProps {
   label?: string | JSX.Element | JSX.Element[];
   classNameLabel?: string;
   text?: string;
   classNameText?: string;
   controlId?: string;
   classNameContainer?: string;
}

const Input = ({
   label, classNameLabel, text,
   classNameText, controlId, classNameContainer,
   ...rest
}: InputPropsI) => {
   return (
      <Form.Group controlId={controlId} className={classNameContainer}>
         <Form.Label className={classNameLabel}>
            {label}
         </Form.Label>
         <FormInput {...rest}/>
         <Form.Text className={classNameText}>
            {text}
         </Form.Text>
      </Form.Group>
   )
}
export default Input;
