import { CatalogsI, CommonI, HuntersI, ServicesI, UserI } from '.';

const commonObject: CommonI = {
   data: [],
   status: {
      state: '',
      fetching: false
   },
   errorMessage: ''
}

export const CatalogsSchema: CatalogsI = Object.freeze({
   banners: commonObject,
   categories: commonObject,
   countries: commonObject,
   products: commonObject,
   zones: commonObject,
   category: {},
   product: {},
   country: {},
   banner: {},
   zone: {}
});

export const HuntersSchema: HuntersI = Object.freeze({
   hunters: commonObject,
   promoCodes: commonObject,
   event: {},
   hunter: {}
});

export const ServiceSchema: ServicesI = Object.freeze({
   currentService: {
      customer: '',
      email: '',
      date: '',
      id: '',
      payment_id: '',
      payment_type: '',
      phone: '',
      price: '',
      state: '',
      status: ''
   },
   status: commonObject.status,
   errorMessage: commonObject.errorMessage
});

export const UserSchema: UserI = Object.freeze({
   data: {
      email: '',
      id: '',
      lastnames: '',
      name: '',
      photo_uri: '',
      profile: '',
      token: '',
      zones: []
   },
   status: commonObject.status,
   errorMessage: commonObject.errorMessage
});
