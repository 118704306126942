import { useAuth } from "hooks";
import { Navigate } from "react-router-dom";

interface PrivateRoutePropsI {
    children: any;
}

const PrivateRoute = ({children}:PrivateRoutePropsI) => {
    const auth = useAuth();
    return auth ? children : <Navigate to="/" />;
}
export default PrivateRoute;