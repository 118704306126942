import { Store } from '@reduxjs/toolkit';
import { getBanners, getCategories, getCountries, getProducts, getZones } from 'api';
import { 
   bannersIsFetching, setBanners as settingBanners, productsIsFetching, setProducts as settingProducts,
   zonesIsFetching, setZones as settingZones, countriesIsFetching, setCountries as settingCountries,
   categoriesIsFetching, setCategories as settingCategories
} from './catalogsSlice';

export const setBanners = () => async (dispatch: Store['dispatch']) => {
   try {
      dispatch(bannersIsFetching());
      const { success, data, errors } = await getBanners();
      if (success === 'true') {
         dispatch(settingBanners({ status: true, data }));
      } else {
         dispatch(settingBanners({ status: false, data: errors }));
      }
   } catch (error:any) {
      dispatch(settingBanners({ status: false, data: error.message }));
   }
}

export const setProducts = () => async (dispatch: Store['dispatch']) => {
   try {
      dispatch(productsIsFetching());
      const { success, data, errors } = await getProducts();
      if (success === 'true') {
         dispatch(settingProducts({ status: true, data }));
      } else {
         dispatch(settingProducts({ status: false, data: errors }));
      }
   } catch (error:any) {
      dispatch(settingProducts({ status: false, data: error.message }));
   }
}

export const setZones = () => async (dispatch: Store['dispatch']) => {
   try {
      dispatch(zonesIsFetching());
      const { success, data, errors } = await getZones();
      if (success === 'true') {
         dispatch(settingZones({ status: true, data }));
      } else {
         dispatch(settingZones({ status: false, data: errors }));
      }
   } catch (error:any) {
      dispatch(settingZones({ status: false, data: error.message }));
   }
}

export const setCountries = () => async (dispatch: Store['dispatch']) => {
   try {
      dispatch(countriesIsFetching());
      const { success, data, errors } = await getCountries();
      if (success === 'true') {
         dispatch(settingCountries({ status: true, data }));
      } else {
         dispatch(settingCountries({ status: true, data: errors }));
      }
   } catch (error:any) {
      dispatch(settingCountries({ status: true, data: error.message }));
   }
}

export const setCategories = () => async (dispatch: Store['dispatch']) => {
   try {
      dispatch(categoriesIsFetching());
      const { success, data, errors } = await getCategories();
      if (success === 'true') {
         dispatch(settingCategories({ status: true, data }));
      } else {
         dispatch(settingCategories({ status: false, data: errors }));
      }
   } catch (error:any) {
      dispatch(settingCategories({ status: false, data: error.message }));
   }
}