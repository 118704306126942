import { Card, Statistic, StatisticProps } from "antd";

interface StatisticCProps extends StatisticProps {
    classCard?: string;
    styleCard?: any;
}

const StatisticCard = ({ classCard, styleCard, ...rest }: StatisticCProps) => {
    return (
        <Card className={classCard} style={styleCard}>
            <Statistic {...rest} />
        </Card>
    )
}
export default StatisticCard;