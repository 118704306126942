import esES from 'antd/lib/locale/es_ES';
import { Empty, notification } from "antd";
import { CommonSearchForm, Table } from "components";
import { Fragment, useEffect, useState } from "react";
import { productColumns } from 'utils/tableColumns';
import { getProductsByCategory, postProduct, putProduct } from 'api';
import ProductFormModal from './ProductFormModal';
import { useAppSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { cleanCurrentProduct } from 'store/catalogs';
import { setProducts } from 'store/catalogs/catalogs_t';

interface ProductsPropsI {
    id: string;
}

const ProductsList = ({ id }: ProductsPropsI) => {
    const [productsList, setProductsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalShowed, setModalShowed] = useState(false);
    const [search, setSearch] = useState("");

    const dispatch = useDispatch();
    const { categories, product, products } = useAppSelector(({catalogs}) => catalogs);
    const { data } = categories;

    const filterProducts = () => {
        if (productsList.length > 0) {
            return productsList.filter((item:any) => {
                const { name, description } = item;
                const tempName = name ? name.es.toLowerCase() : "";
                const tempDescription = description ? description.es.toLowerCase() : "";
                return tempName.match(search.toLowerCase()) || tempDescription.match(search.toLowerCase());
            });
        }
    }

    const gettingProducts = async () => {
        try {
            if (!isLoading) setIsLoading(true);
            const { success, data, errors } = await getProductsByCategory(id);
            if (success === "true") {
                setProductsList(data);
            } else {
                setProductsList([]);
                notification.error({
                    message: "Productos",
                    description: `Ocurrió un error. ${errors}`
                });
            }
            setIsLoading(false);
        } catch (error:any) {
            setProductsList([]);
            setIsLoading(false);
            notification.error({
                message: "Productos",
                description: `Ocurrió un error. ${error.message}`
            });
        }
    }

    const registerProduct = async (body:any) => {
        try {
            const aux = [...products.data];
            aux.sort((a:any, b:any) => a.order - b.order);
            
            const { success, errors } = Object.keys(product).length > 0 ?
            await putProduct(product.id, {
                product: {
                    ...body,
                    category_id: id,
                    order: product.order
                }})
            :
            await postProduct({
                product: {
                    ...body,
                    category_id: id,
                    order: aux[aux.length - 1].order + 1
                }});

            if (success === "true") {
                dispatch(cleanCurrentProduct());
                dispatch(setProducts());
                gettingProducts();
                onCloseModal();
                notification.success({
                    message: `${Object.keys(product).length > 0 ? "Edición" : "Registro"} de Producto`,
                    description: `${Object.keys(product).length > 0 ? "La Edición" : "El Registro"} se realizó de forma exitosa`
                });
            } else {
                notification.error({
                    message: `${Object.keys(product).length > 0 ? "Edición" : "Registro"} de Producto`,
                    description: errors
                });
            }
        } catch (error:any) {
            notification.error({
                message: `${Object.keys(product).length > 0 ? "Edición" : "Registro"} de Producto`,
                description: error.message
            });
        }
    }

    const onCloseModal = () => {
        setModalShowed(false);
        dispatch(cleanCurrentProduct());
    }

    useEffect(() => {
        if(id) gettingProducts();
    }, [])

    return (
        <Fragment>
            <CommonSearchForm
                clickAdd={() => setModalShowed(true)}
                search={search}
                onSearch={({ target }: any) => setSearch(target.value.trimStart())}
            />
            <Table
                loading={isLoading}
                localeProv={esES}
                columns={productColumns(dispatch, () => setModalShowed(true))}
                dataSource={search ? filterProducts() : productsList || []}
                size="small"
                rowKey={'id'}
                locale={{
                    triggerDesc: 'Ordenar de forma descendente',
                    triggerAsc: 'Ordenar de forma ascendente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No hay datos"
                    />
                    )
                }}
                scroll={{ x: 'auto' }}
                pagination={{ defaultPageSize: 10 }}
            />
            {modalShowed &&
                <ProductFormModal
                    visible={modalShowed}
                    closeModal={onCloseModal}
                    okHandler={registerProduct}
                    categoryName={data && data.length > 0 ? data.find((item:any) => item.id.toString() === id)?.name : ""}
                />
            }
        </Fragment>
    )
}
export default ProductsList;