import { Store } from "@reduxjs/toolkit";
import { getAllHunters, getEvents } from "api";
import { eventsIsFetching, huntersIsFetching, setAllHunters, setPromoCodes } from "./huntersSlice";

export const setEvents = () => async (dispatch: Store["dispatch"]) => {
   try {
      dispatch(eventsIsFetching());
      const { success, data, errors } = await getEvents();
      if (success === "true") {
         dispatch(setPromoCodes({ status: true, data }));
      } else {
         dispatch(setPromoCodes({ status: false, data: errors }));
      }
   } catch (error:any) {
      dispatch(setPromoCodes({ status: false, data: error.message }));
   }
}

export const setHunters = () => async (dispatch: Store["dispatch"]) => {
   try {
       dispatch(huntersIsFetching());
       const { success, data, errors } = await getAllHunters();
       if (success === "true") {
         dispatch(setAllHunters({ status: true, data }));
       } else {
         dispatch(setAllHunters({ status: false, data: errors }));
       }
   } catch (error:any) {
      dispatch(setAllHunters({ status: false, data: error.message }));
   }
}