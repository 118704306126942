import { Dayjs } from 'dayjs';
import * as React from 'react';
import DatePicker from './AntdDatePicker';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';

export interface TimePickerProps extends Omit<PickerTimeProps<Dayjs>, 'picker'> {}

const AntdTimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => {
  return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />;
});

AntdTimePicker.displayName = 'TimePicker';

export default AntdTimePicker;