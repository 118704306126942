import { Button, Divider, Form, Input, InputMask, Modal } from "components";
import { useAppSelector, useValidation } from "hooks";
import { useEffect, useState } from "react";
import { Col, Row, Modal as M } from "react-bootstrap";
import { maskString, ucFirst } from "utils/formatter";

const PHONE_LENGTH = 10;

interface AddHuntersI {
    okHandler: (object:any) => void;
    visible: boolean;
    closeModal: () => void;
    loading?: boolean;
}

const AddHunters = ({ okHandler, visible, closeModal, loading }: AddHuntersI) => {
    const [names, setNames] = useState("");
    const [lastName, setLastName] = useState("");
    const [sndLastName, setSndLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [emailInvFormat, setEmailInvFormat] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    // hook validation
    const { wordValidation, emailValidation } = useValidation();
    const { hunter } = useAppSelector(({hunters}) => hunters)
    // events handler
    const handleChange = (e: any) => {
        const { name, value } = e.target
        switch (name) {
            case 'name':
                setNames(prev => wordValidation(value) ? ucFirst(value) : prev)
                break
            case 'lastName':
                setLastName(prev => wordValidation(value) ? ucFirst(value) : prev)
                break
            case 'secondlastName':
                setSndLastName(prev => wordValidation(value) ? ucFirst(value) : prev)
                break
            case 'phone':
                setPhone(maskString(value.trim(), "## #### ####"))
                break
            case 'email':
                setEmail(value.toLowerCase().trim())
                break
            default:
                return null;
        }
    }

    const handleChangeMaskedInput = (value:any) => {
        setPhone(value);
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        okHandler({
            hunter: {
                email,
                name: names,
                lastnames: `${lastName}${sndLastName ? ` ${sndLastName}` : ''}`,
                phone: phone.replace(/ /g,"")
            }
        });
    }

    const handleClearState = () => {
        setNames("");
        setLastName("");
        setSndLastName("");
        setPhone("");
        setEmail("");
    }

    const handleHideModal = () => {
        closeModal();
    }

    const handleValidation = () => {
        if (names && lastName && phone.replace(/ /g,"").length === PHONE_LENGTH && emailValidation(email)) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }

        if (!email || emailValidation(email)) {
            return setEmailInvFormat(false);
        }
        setEmailInvFormat(true);
    }
    // effects handler
    useEffect(() => {
        handleValidation();
    },[names, lastName, sndLastName, phone, email])

    useEffect(() => {
        if (!visible) {
            handleClearState();
        }
    }, [visible])

    useEffect(() => {
        if (Object.keys(hunter).length > 0) {
            const namesArr = hunter.lastnames ? hunter.lastnames.split(" ") : [];
            const firstLName = namesArr.length > 0 ? namesArr[0] : "";
            let sndLName = "";
            if (namesArr.length > 1) {
                namesArr.splice(0, 1);
                sndLName = namesArr.join(" ");
            }
            setNames(hunter.name);
            setLastName(firstLName);
            setSndLastName(sndLName);
            setPhone(hunter.phone);
            setEmail(hunter.email);
        }
    }, [])

    return (
        <Modal show={visible} centered size="lg"
            onHide={handleHideModal} backdrop="static" keyboard={false}
        >
            <M.Header closeButton>
                <h6 className="m-0">{`${Object.keys(hunter).length > 0 ? "Detalle" : "Nuevo"} Hunter`}</h6>
            </M.Header>
            <M.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Input
                                classNameContainer="mb-2" 
                                label="Nombre (s)"
                                name="name"
                                id="name"
                                value={names}
                                onChange={handleChange}
                                autoFocus
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <Input
                                classNameContainer="mb-2" 
                                label="Apellido Paterno"
                                name="lastName"
                                id="lastName"
                                value={lastName}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <Input
                                classNameContainer="mb-2" 
                                label="Apellido Materno"
                                name="secondlastName"
                                id="secondlastName"
                                value={sndLastName}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <Input
                                classNameContainer="mb-2" 
                                label="Email"
                                name="email"
                                id="email"
                                value={email}
                                onChange={handleChange}
                                text={emailInvFormat ? "Formato de Correo Invalido" : ""}
                                classNameText={emailInvFormat ? "wrong-text" : ""}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <InputMask
                                classNameContainer="mb-2"
                                name="phone"
                                label="Teléfono"
                                value={phone}
                                mask="99 9999 9999"
                                onChange={handleChangeMaskedInput}
                            />
                        </Col>
                        <Divider style={{ margin: "16px 0"}} />
                        <Col style={{ textAlign: "right"}} sm={12} md={{ offset: 6, span: 6}} lg={{ offset: 6, span: 6}}>
                            <Button  size="sm" className="me-3 pry-outline-btn"
                                onClick={handleHideModal}
                            >
                                {"Cancelar"}
                            </Button>
                            <Button size="sm" type="submit" className="primary-btn"
                                disabled={isDisabled}
                                loading={loading}
                            >
                                {"Enviar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </M.Body>
        </Modal>
    )
}
export default AddHunters;