import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'store/globalactions';
import { CatalogsSchema } from 'store/schema';

export const catalogSlice = createSlice({
   name: 'CATALOGS',
   initialState: CatalogsSchema,
   reducers: {
      bannersIsFetching: (state) => { 
         state.banners.status.fetching = true;
         state.banners.status.state = 'FETCHING';
         state.banners.errorMessage = '';
         state.banners.data = [];
      },
      setBanners: (state, { payload }) => {
         state.banners.status.fetching = false;
         state.banners.status.state = payload.status ? 'SUCCESS' : 'ERROR';
         state.banners.errorMessage = payload.status ? '' : payload.data;
         state.banners.data = payload.status ? payload.data : [];
      },
      cleanCurrentBanner: (state) => {
         state.banner = {};
      },
      setCurrentBanner: (state, { payload }) => {
         state.banner = payload;
      },
      productsIsFetching: (state) => { 
         state.products.status.fetching = true;
         state.products.status.state = 'FETCHING';
         state.products.errorMessage = '';
         state.products.data = [];
      },
      setProducts: (state, { payload }) => { 
         state.products.status.fetching = false;
         state.products.status.state = payload.status ? 'SUCCESS' : 'ERROR';
         state.products.errorMessage = payload.status ? '' : payload.data;
         state.products.data = payload.status ? payload.data : [];
      },
      cleanCurrentProduct: (state) => {
         state.product = {};
      },
      setCurrentProduct: (state, { payload }) => {
         state.product = payload;
      },
      zonesIsFetching: (state) => { 
         state.zones.status.fetching = true;
         state.zones.status.state = 'FETCHING';
         state.zones.errorMessage = '';
         state.zones.data = [];
      },
      setZones: (state, { payload }) => { 
         state.zones.status.fetching = false;
         state.zones.status.state = payload.status ? 'SUCCESS' : 'ERROR';
         state.zones.errorMessage = payload.status ? '' : payload.data;
         state.zones.data = payload.status ? payload.data : [];
      },
      cleanCurrentZone: (state) => {
         state.zone = {};
      },
      setCurrentZone: (state, { payload }) => {
         state.zone = payload;
      },
      countriesIsFetching: (state) => { 
         state.countries.status.fetching = true;
         state.countries.status.state = 'FETCHING';
         state.countries.errorMessage = '';
         state.countries.data = [];
      },
      setCountries: (state, { payload }) => { 
         state.countries.status.fetching = false;
         state.countries.status.state = payload.status ? 'SUCCESS' : 'ERROR';
         state.countries.errorMessage = payload.status ? '' : payload.data;
         state.countries.data = payload.status ? payload.data : [];
      },
      cleanCurrentCountry: (state) => {
         state.country = {};
      },
      setCurrentCountry: (state, { payload }) => {
         state.country = payload;
      },
      categoriesIsFetching: (state) => { 
         state.categories.status.fetching = true;
         state.categories.status.state = 'FETCHING';
         state.categories.errorMessage = '';
         state.categories.data = [];
      },
      setCategories: (state, { payload }) => { 
         state.categories.status.fetching = false;
         state.categories.status.state = payload.status ? 'SUCCESS' : 'ERROR';
         state.categories.errorMessage = payload.status ? '' : payload.data;
         state.categories.data = payload.status ? payload.data : [];
      },
      cleanCurrentCategory: (state) => {
         state.category = {};
      },
      setCurrentCategory: (state, { payload }) => {
         state.category = payload;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(logout, () => {
        return CatalogsSchema;
      });
   },
});

export const {
   bannersIsFetching, setBanners, productsIsFetching, setProducts, zonesIsFetching, setZones,
   countriesIsFetching, setCountries, categoriesIsFetching, setCategories, cleanCurrentBanner,
   setCurrentBanner, cleanCurrentProduct, setCurrentProduct, cleanCurrentZone, setCurrentZone,
   cleanCurrentCountry, setCurrentCountry, cleanCurrentCategory, setCurrentCategory
} = catalogSlice.actions;
