import {
    BarcodeOutlined, CustomerServiceOutlined, DashboardOutlined, DoubleRightOutlined, 
    EnvironmentOutlined, GlobalOutlined, HistoryOutlined,/* MailOutlined ,*/
    ScissorOutlined, SettingOutlined, TeamOutlined, UngroupOutlined
  } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getItem } from 'utils/tableColumns';

export const menus = [
    getItem(<Link to={"/home"}>{"Dashboard"}</Link>, 1, () => {}, <DashboardOutlined />),
    getItem("Codigos", 2, () => {}, <BarcodeOutlined />, [
        getItem(<Link to={"/coupons"}>{"Eventos"}</Link>, 21, () => {}, <DoubleRightOutlined />),
        getItem(<Link to={"/hunters"}>{"Hunters"}</Link>, 22, () => {}, <TeamOutlined />),
    ]),
    getItem("Servicios", 3, () => {}, <CustomerServiceOutlined />, [
        getItem(<Link to={"/services/day-services"}>{"Del Día"}</Link>, 31, () => {}, <CustomerServiceOutlined />),
        getItem(<Link to={"/services/nexts"}>{"Próximos"}</Link>, 32, () => {}, <DoubleRightOutlined />),
        getItem(<Link to={"/services/historical"}>{"Historial"}</Link>, 33, () => {}, <HistoryOutlined />),
    ]),
    getItem("Configuración", 4, () => {}, <SettingOutlined />, [
        getItem(<Link to={"/countries"}>{"Países"}</Link>, 41, () => {}, <GlobalOutlined />),
        getItem(<Link to={"/zones"}>{"Zonas"}</Link>, 42, () => {}, <EnvironmentOutlined />),
        getItem(<Link to={"/goodlooks"}>{"Goodlookeros"}</Link>, 43, () => {}, <ScissorOutlined />),
        getItem(<Link to={"/categories"}>{"Categorías"}</Link>, 44, () => {}, <UngroupOutlined />),
        getItem(<Link to={"/products"}>{"Productos"}</Link>, 45, () => {}, <UngroupOutlined />),
        getItem(<Link to={"/banners"}>{"Banners"}</Link>, 46, () => {}, <UngroupOutlined />),
    ]),
]
