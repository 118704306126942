import { Button, DatePicker, Divider, Form, Input, InputNumber, Modal, Select } from "components";
import dayjs from "dayjs";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { Col, Row, Modal as M } from "react-bootstrap";
import { activeEnum, typeEnum } from "utils/enums";

interface AddHuntersI {
    okHandler: (object:any) => void;
    visible: boolean;
    closeModal: () => void;
    loading?: boolean;
}

const CODE_MIN_LENGTH = 5;
const CODE_MAX_LENGTH = 15;

const AddEvent = ({ okHandler, visible, closeModal, loading }: AddHuntersI) => {
    // Redux constants
    const { hunters, event } = useAppSelector(({hunters}) => hunters);
    const {data} = hunters;
    // status component constants
    const [code, setCode] = useState("");
    const [description, setDescription] = useState("");
    const [discount, setDiscount] = useState<string | number>("0");
    const [startAt, setStartAt] = useState(dayjs());
    const [expireAt, setExpireAt] = useState(dayjs());
    const [statusEvent, setStatusEvent] = useState("");
    const [hunter, setHunter] = useState("");
    const [type, setType] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);

    // events handler
    const handleChange = (e: any) => {
        const { id, value } = e.target;
        switch (id) {
            case "code":
                setCode(value ? value.toUpperCase().trim() : '');
                break;
            case 'description':
                setDescription(value);
                break
            default:
                return null;
        }
    }

    const handleDateTimePicker = (typed:string) => (date:any) => {
        if (typed === "start")
            setStartAt(date);
        else
            setExpireAt(date);
    }

    const handleNumberInput = (value:any) => {
        setDiscount(value);
    }

    const handleSelect = (typed: string) => (value:any) => {
        switch (typed) {
            case "statusEvent":
                setStatusEvent(value);
                break;
            case 'hunter':
                setHunter(value);
                break
            case 'type':
                setType(value);
                break
            default:
                return null;
        }
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        const hunterExist = data.find((item:any) => item.id === hunter);
        okHandler(!hunterExist ? {
            promo_code: {
                code,
                description,
                discount,
                expires_at: expireAt,
                starts_at: startAt,
                status: statusEvent === "active",
                type
            }
        } : {
            promo_code: {
                code,
                description,
                discount,
                expires_at: expireAt,
                starts_at: startAt,
                status: statusEvent === "active",
                type,
                hunter_id: hunterExist.id
            }
        });
    }

    const handleClearState = () => {
        setCode("");
        setDescription("");
        setDiscount("");
        setStartAt(dayjs());
        setExpireAt(dayjs());
        setStatusEvent("");
    }

    const handleHideModal = () => {
        closeModal();
    }

    const validateForm = () => {
        if ((code.length >= CODE_MIN_LENGTH && code.length <= CODE_MAX_LENGTH) && description && (discount !== "" && Number(discount) < 100 && Number(discount) >= 0) && statusEvent && type) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }

    useEffect(() => {
        validateForm();
    }, [code, description, discount, statusEvent, type, startAt, expireAt])

    useEffect(() => {
        if (!visible) {
            handleClearState();
        }
    }, [visible])

    useEffect(() => {
        if (Object.keys(event).length > 0) {
            setCode(event.code);
            setDescription(event.description);
            setStartAt(dayjs(event.starts_at));
            setExpireAt(dayjs(event.expires_at));
            setDiscount(event.discount);
            setStatusEvent(event.status ? "active" : "inactive");
            setType(event.type);
            setHunter(event.hunter ? event.hunter.id : 0);
        }
    }, [])

    return (
        <Modal show={visible} centered size="lg"
            onHide={handleHideModal} backdrop="static" keyboard={false}
        >
            <M.Header closeButton>
                <h6 className="m-0">{`${Object.keys(event).length > 0 ? "Detalle" : "Nuevo"} Evento`}</h6>
            </M.Header>
            <M.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={12} md={6} lg={6}>
                            <Input
                                classNameContainer="mb-2" 
                                label="Código"
                                name="code"
                                id="code"
                                text={`Min ${CODE_MIN_LENGTH} - Max ${CODE_MAX_LENGTH} caracteres`}
                                maxLength={15}
                                value={code}
                                onChange={handleChange}
                                autoFocus
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <Input
                                classNameContainer="mb-2" 
                                label="Descripción"
                                name="description"
                                id="description"
                                value={description}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <DatePicker
                                classNameContainer="mb-2"
                                classNameLabel="w-100"
                                label="Inicia"
                                onChange={handleDateTimePicker("start")}
                                value={startAt}
                                style={{ width: "100%" }}
                                format={'DD/MMM/YYYY'}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <DatePicker
                                classNameContainer="mb-2"
                                classNameLabel="w-100"
                                label="Expira"
                                onChange={handleDateTimePicker("expire")}
                                value={expireAt}
                                style={{ width: "100%" }}
                                format={'DD/MMM/YYYY'}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <InputNumber
                                classNameContainer="mb-2" 
                                label="Descuento"
                                classNameLabel="w-100"
                                style={{ width: "100%" }}
                                name="discount"
                                id="discount"
                                value={discount}
                                onChange={handleNumberInput}
                                min={0}
                                max={100}
                                maxLength={4}
                                defaultValue={discount}
                                formatter={(value:any) => `${value}%`}
                                parser={(value:any) => value.replace('%', '')}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <Select
                                id="statusEvent"
                                classNameContainer="mb-2"
                                label="Estatus"
                                classNameLabel="w-100"
                                options={activeEnum.map((item) => ({ value: item.value, label: item.label }))}
                                value={statusEvent}
                                onChange={handleSelect("statusEvent")}
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <Select
                                id="type"
                                classNameContainer="mb-2"
                                label="Tipo"
                                classNameLabel="w-100"
                                options={typeEnum}
                                value={type}
                                onChange={handleSelect("type")}
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <Select
                                id="hunter"
                                classNameContainer="mb-2"
                                label="Hunter"
                                classNameLabel="w-100"
                                options={data ? [{label: "(Ninguno)", value: 0},...data.map((item:any) => ({value: item.id,label: `${item.name || ''}${item.lastnames ? ` ${item.lastnames}` : ""}`}))] : []}
                                value={hunter}
                                onChange={handleSelect("hunter")}
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Divider style={{ margin: "16px 0"}} />
                        <Col style={{ textAlign: "right"}} sm={12} md={{ offset: 6, span: 6}} lg={{ offset: 6, span: 6}}>
                            <Button size="sm" className="me-3 pry-outline-btn"
                                onClick={handleHideModal}
                            >
                                {"Cancelar"}
                            </Button>
                            <Button size="sm" type="submit" className="primary-btn"
                                disabled={isDisabled}
                                loading={loading}
                            >
                                {"Enviar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </M.Body>
        </Modal>
    )
}
export default AddEvent;