import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Col, Menu, MenuProps, Row } from "antd";
import { Avatar, Dropdown, Image } from "components";
import dayjs from "dayjs";
import es from "dayjs/locale/es";
import I18n from "i18n-js";
import { Fragment } from "react";
import { setCurrentBanner, setCurrentCategory, setCurrentCountry, setCurrentProduct, setCurrentZone } from "store/catalogs";
import { setCurrentEvent, setCurrentHunter } from "store/hunters";
import { maskString } from "./formatter";
import { getBooleanText, getCustomBadge } from "./getComponents";
import { setCurrentService } from "store/service";

dayjs.locale(es);

const textLeft = "left";
const textRight = "right";
const textCenter = "center";
const textAscend = "ascend";

type MenuItem = Required<MenuProps>['items'][number];

export const getItem = (
  label: React.ReactNode,
  key: React.Key,
  onClick?: () => void,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem => {
  return {
    key,
    onClick,
    label,
    icon,
    children
  } as MenuItem;
}

export const dayServicesColumns = (navigate:any, dispatch:any) => {
    const MenuOptions = (row: any) => {
        return (
            <Menu items={[getItem("Editar", 1, () => {
                dispatch(setCurrentService(row));
                navigate(`/services/detail/${row.id}`);
            })]} />
        );
    };
    return ([
        {
            title: "Folio",
            dataIndex: "id",
            key: "id",
            fixed: textLeft as "left",
            sorter: (a:any, b:any) => a.id - b.id,
            defaultSortOrder: textAscend as 'ascend',
        },
        {
            title: "Nombre",
            dataIndex: "customer",
            key: "customer",
            sorter: (a:any, b:any) => a.customer.localeCompare(b.customer)
        },
        {
            title: "Ciudad",
            dataIndex: "state",
            key: "state",
            sorter: (a:any, b:any) => {
                const stateA = a.state || '';
                const stateB = b.state || '';
                return stateA.localeCompare(stateB)
            }
        },
        {
            title: "Teléfono",
            dataIndex: "phone",
            key: "phone",
            width: "auto",
            render: (text: any) => {
                const phone = text ? text.replace(/[()]/g, "") : "";
                return (
                    <p className="m-0">{maskString(phone ? phone.replace(/ /g,"") : "", "## #### ####")}</p>
                );
            }
        },
        {
            title: "Inicia",
            dataIndex: "date",
            key: "date",
            render: (text:any) => <p className="m-0 text-uppercase">{dayjs(text).format('DD / MMM / YYYY')}</p>,
            sorter: (a:any, b:any) => {
                const date1 = new Date(a.date);
                const date2 = new Date(b.date);
                return date2.valueOf() - date1.valueOf();
            },
        },
        {
            title: "Hora",
            dataIndex: "date",
            key: "date",
            render: (text:any) => <p className="m-0">{dayjs(text).format('hh:mm A')}</p>
        },
        {
            title: "Tipo Pago",
            dataIndex: "payment_type",
            key: "payment_type",
            render: (text:any, record:any) => <Fragment>{text === "cash" ? record.payment_engine === 'Manual' ? 'Transfer' : "Efectivo" : "Tarjeta"}</Fragment>,
            sorter: (a:any, b:any) => a.payment_type.localeCompare(b.payment_type),
        },
        {
            title: "Costo",
            dataIndex: "price",
            key: "price",
            render: (text:any) => I18n.l('currency', text || 0),
            sorter: (a:any, b:any) => b.price - a.price,

        },
        {
            title: "Moneda",
            dataIndex: "currency",
            key: "currency",
            render: (text:any) => <p style={{ margin: 0, textAlign: 'center' }}>{text}</p>,
            sorter: (a:any, b:any) => { return  a.currency.toLowerCase() > b.currency.toLowerCase() ? 1 : -1 } ,

        },
        {
            title: "Tipo",
            dataIndex: "payment_engine",
            key: "payment_engine",
            render: (text:any) => text === 'Stripe' ? 'Web' : text === 'Manual' ? 'Adm' : 'App',
        },
        {
            title: "Estatus",
            dataIndex: "status",
            key: "status",
            render: (text:any) => getCustomBadge(text, true),
            sorter: (a:any, b:any) => getBooleanText(a.status).localeCompare(getBooleanText(b.status))
        },
        {
            title: "Acciones",
            key: "action",
            align: textCenter as "center",
            fixed: textRight as "right",
            width: "6em",
            render: (record: any) => {
                return (
                    <Dropdown overlay={MenuOptions(record)} trigger={["click"]}>
                        <MoreOutlined />
                    </Dropdown>
                );
            },
        }
    ]);
}

export const eventColumns = (dispatch:any, showModal:any) => {
    const MenuOptions = (row: any) => {
        return (
            <Menu items={[getItem("Editar", 1, () => {
                dispatch(setCurrentEvent(row));
                showModal();
            })]} />
        );
    };

    return ([
        {
            title: "Código",
            dataIndex: "code",
            key: "code",
            fixed: textLeft as "left"
        },
        {
            title: "Descripción",
            dataIndex: "description",
            key: "description"
        },
        {
            title: "Descuento",
            dataIndex: "discount",
            key: "discount",
            render: (text:any) => `${text} %`,
            sorter: (a:any, b:any) => b.discount - a.discount
        },
        {
            title: "Inicia",
            dataIndex: "starts_at",
            key: "starts_at",
            render: (text:any) => <p className="m-0 text-uppercase">{dayjs(text).format('DD / MMM / YYYY')}</p>,
            sorter: (a:any, b:any) => {
                const date1 = new Date(a.starts_at)
                const date2 = new Date(b.starts_at)
                return date2.valueOf() - date1.valueOf();
            },
            defaultSortOrder: textAscend as 'ascend',
        },
        {
            title: "Expira",
            dataIndex: "expires_at",
            key: "expires_at",
            render: (text:any) => <p className="m-0 text-uppercase">{dayjs(text).format('DD / MMM / YYYY')}</p>,
            sorter: (a:any, b:any) => {
                const date1 = new Date(a.expires_at)
                const date2 = new Date(b.expires_at)
                return date2.valueOf() - date1.valueOf();
            },
        },
        {
            title: "Estatus",
            dataIndex: "status",
            key: "status",
            render: (text:any) => getCustomBadge(text ? "active" : "inactive", true),
            sorter: (a:any, b:any) => getBooleanText(a.status).localeCompare(getBooleanText(b.status)),
        },
        {
            title: "Hunter",
            dataIndex: "hunter",
            key: "hunter",
            render: (text:any) => {
                return <Fragment>{text ? `${text.name}${text.lastnames ? ` ${text.lastnames}`  : ""}` : "Null"}</Fragment>
            }
        },
        {
            title: "Uso",
            dataIndex: "uses",
            key: "uses",
            fixed: textRight as "right"
        },
        {
            title: "Acciones",
            key: "action",
            align: textCenter as "center",
            fixed: textRight as "right",
            width: "6em",
            render: (record: any) => {
                return (
                    <Dropdown overlay={MenuOptions(record)} trigger={["click"]}>
                        <MoreOutlined />
                    </Dropdown>
                );
            },
        }
    ]);
}

export const hunterColumns = (dispatch:any, showModal:any) => {
    const MenuOptions = (record:any) => {
        return (
            <Menu items={[getItem("Editar", 1, () => {
                dispatch(setCurrentHunter(record));
                showModal();
            })]} />
        );
    }

    return ([
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            fixed: textLeft as "left",
            width: "250px",
            sorter: (a:any, b:any) => {
                const nameA = `${a.name || ''} ${a.lastnames}`;
                const nameB = `${b.name || ''} ${b.lastnames}`;
                return nameA.localeCompare(nameB)
            },
            render: (text: any, row: any) => {
                return <p className="m-0 text-capitalize">{`${text} ${row.lastnames}`}</p>
            }
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: "auto",
            render: (text: any) => {
                return <p className="m-0">{`${text ? text.toLowerCase() : ''}`}</p>
            }
        },
        {
            title: "Teléfono",
            dataIndex: "phone",
            key: "phone",
            fixed: textRight as "right",
            width: "calc(20%)",
            render: (text: any) => {
                const phone = text ? text.replace(/[()]/g, "") : "";
                return <p className="m-0 text-capitalize">{maskString(phone ? phone.replace(/ /g,"") : "", "## #### ####")}</p>
            }
        },
        {
            title: "Acciones",
            key: "action",
            align: textCenter as "center",
            fixed: textRight as "right",
            width: "6em",
            render: (record: any) => {
                return (
                    <Dropdown overlay={MenuOptions(record)} trigger={["click"]}>
                        <MoreOutlined />
                    </Dropdown>
                );
            },
        },
    ]);
}

export const countryColumns = (dispatch:any, showModal:any) => {
    const MenuOptions = (row:any) => {
        return (
            <Menu items={[getItem("Editar", 1, () => {
                dispatch(setCurrentCountry(row));
                showModal();
            })]} />
        );
    }

    return ([
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            fixed: textLeft as "left"
        },
        {
            title: "País",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Factor",
            dataIndex: "factor",
            key: "factor"
        },
        {
            title: "Moneda",
            dataIndex: "currency",
            key: "currency",
            fixed: textRight as "right"
        },
        {
            title: "Acciones",
            key: "action",
            align: textCenter as "center",
            fixed: textRight as "right",
            width: "6em",
            render: (record: any) => {
                return (
                    <Dropdown overlay={MenuOptions(record)} trigger={["click"]}>
                        <MoreOutlined />
                    </Dropdown>
                );
            },
        }
    ]);
}

export const goodLooksColumns = (navigate:any) => {
    const MenuOptions = (row: any) => {
        return (
            <Menu items={[getItem("Editar", 1, () => {
                navigate(`/goodlooks/${row.id}`);
            })]} />
        );
    };
    return ([
        {
            title: "Folio",
            dataIndex: "id",
            key: "id",
            fixed: textLeft as "left",
        },
        {
            title: "Avatar",
            dataIndex: "photo_uri",
            key: "photo_uri",
            fixed: textLeft as "left",
            align: textCenter as "center",
            render:(text:any, row:any) => text ? 
                <Avatar margin={"auto"} size={"small"} icon={<Image style={{ borderRadius: "50%" }} preview={false} image={text} alt="gl-logo" />} />
                :
                <Avatar margin={"auto"} size={"small"} nameStyle={{ fontSize: 12 }} backgroundColor="#FC4B6C" name={`${row.name} ${row.lastnames || ""}`} />
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            render: (text:any, row:any) => <Fragment>{`${text} ${row.lastnames}`}</Fragment>
        },
        {
            title: "Teléfono",
            dataIndex: "phone",
            key: "phone"
        },
        {
            title: "Correo Electrónico",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Acciones",
            key: "action",
            align: textCenter as "center",
            fixed: textRight as "right",
            width: "6em",
            render: (record: any) => {
                return (
                    <Dropdown overlay={MenuOptions(record)} trigger={["click"]}>
                        <MoreOutlined />
                    </Dropdown>
                );
            },
        }
    ]);
}

export const categoryColumns = (dispatch:any, showModal:any) => {
    const MenuOptions = (row: any) => {
        return (
            <Menu items={[getItem("Editar", 1, () => {
                dispatch(setCurrentCategory(row));
                showModal();
            })]} />
        );
    };

    return ([
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            fixed: textLeft as "left",
            sorter: (a:any, b:any) => a.id - b.id,
        },
        {
            title: "Categoría",
            dataIndex: "name",
            key: "name",
            sorter: (a:any, b:any) => a.name.es.localeCompare(b.name.es),
            render: (text:any) => text ? text.es : ""
        },
        {
            title: "Descripción",
            dataIndex: "description",
            key: "description",
            render: (text:any) => text ? text.es : ""
        },
        {
            title: "Estatus",
            dataIndex: "status",
            key: "status",
            render: (text:any) => getCustomBadge(text ? "active" : "inactive", true),
            sorter: (a:any, b:any) => getBooleanText(a.status).localeCompare(getBooleanText(b.status)),
            defaultSortOrder: textAscend as 'ascend',
        },
        {
            title: "Cover",
            dataIndex: "cover",
            key: "cover",
            align: textCenter as "center",
            render: (text:any) => <Image image={text} preview={false} style={{ height: 100 }}/>
        },
        {
            title: "Imagen",
            dataIndex: "photo",
            key: "photo",
            fixed: textRight as "right",
            align: textCenter as "center",
            render: (text:any) => <Image image={text} preview={false} style={{ width: 100 }}/>
        },
        {
            title: "Acciones",
            key: "action",
            align: textCenter as "center",
            fixed: textRight as "right",
            width: "6em",
            render: (record: any) => {
                return (
                    <Dropdown overlay={MenuOptions(record)} trigger={["click"]}>
                        <MoreOutlined />
                    </Dropdown>
                );
            },
        }
    ]);
}

export const productColumns = (dispatch:any, showModal:any) => {

    const MenuOptions = (row: any) => {
        const items: MenuProps['items'] = [
            {
                key: '1',
                label: 'Editar',
                onClick: ()=>{
                    dispatch(setCurrentProduct(row));
                    showModal();
                }
            },
        ];
        return {items} ;
    };

    return ([
        {
            title: "Order",
            dataIndex: "order",
            key: "order",
            fixed: textLeft as "left",
            sorter: (a:any, b:any) => a.order - b.order,
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            sorter: (a:any, b:any) => a.name.es.localeCompare(b.name.es),
            render: (text:any) => text ? text.es : ""
        },
        {
            title: "Descripción",
            dataIndex: "description",
            key: "description",
            render: (text:any) => text ? text.es : ""
        },
        {
            title: "MXN",
            dataIndex: "sell_price",
            key: "sell_price",
            render: (text:any) => I18n.l('currency', text || 0),
            sorter: (a:any, b:any) => a.sell_price - b.sell_price,
        },
        {
            title: "USD",
            dataIndex: "sell_price_usd",
            key: "sell_price_usd",
            render: (text:any) => I18n.l('currency', text || 0),
            sorter: (a:any, b:any) => a.sell_price_usd - b.sell_price_usd,
        },
        {
            title: "Estatus",
            dataIndex: "status",
            key: "status",
            render: (text:any) => getCustomBadge(text ? "active" : "inactive", true),
            sorter: (a:any, b:any) => getBooleanText(a.status).localeCompare(getBooleanText(b.status)),
            defaultSortOrder: textAscend as 'ascend',
        },
        {
            title: "Imagen",
            dataIndex: "photo",
            key: "photo",
            fixed: textRight as "right",
            align: textCenter as "center",
            render: (text:any) => <Image image={text} preview={false} style={{ width: 250 }}/>
        },
        {
            title: "Acciones",
            key: "action",
            align: textCenter as "center",
            fixed: textRight as "right",
            width: "6em",
            render: (record: any) => {
                return (
                    <Dropdown menu={MenuOptions(record)} trigger={["click"]}>
                        <MoreOutlined />
                    </Dropdown>
                );
            },
        }
    ]);
}

export const cartColumns = (currency:string, updateCount: any, removeItem:any) => {


    return ([
        {
            title: "Imagen",
            dataIndex: "photo",
            key: "photo",
            fixed: textRight as "right",
            align: textCenter as "center",
            render: (text:any) => <Image className="avatar-icon" image={text} preview={false}  />
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            align: textCenter as "center",
            fixed: textRight as "right",
            sorter: (a:any, b:any) => a.name.es.localeCompare(b.name.es),
            render: (text:any) => text ? text.es : ""
        },
        {
            title: currency,
            dataIndex: `${currency === 'MXN' ? "sell_price" : "sell_price_usd" }` ,
            key: `${currency === 'MXN' ? "sell_price" : "sell_price_usd" }`,
            align: textCenter as "center",
            fixed: textRight as "right",
            render: (text:any, record:any) => I18n.l('currency', text * record.count || 0),
            sorter: (a:any, b:any) =>  currency === 'MXN' ? a.sell_price - b.sell_price : a.sell_price_usd - b.sell_price_usd   ,
        },
        {
            title: "Cantidad",
            dataIndex: "count",
            key: "count",
            width: "12em",
            fixed: textRight as "right",
            align: textCenter as "center",
            render: (text:any, record:any) => {
                return (
                    <Row style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}} >
                        <Col style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '85%'}}>
                            <Button className="btnCart" disabled={record.count === 1} onClick={()=> updateCount(record.order, 'subtract')} >-</Button>
                                {text}
                            <Button className="btnCart" onClick={()=> updateCount(record.order, 'add')} >+</Button>
                        </Col>
                        <Col style={{width: '10%', cursor: 'pointer'}} >
                            <DeleteOutlined onClick={()=>removeItem(record.order)} className="deleteIcon" />
                        </Col>
                    </Row>
                )
            },
        },
    ]);
}

export const bannerColumns = (dispatch:any, showModal:any) => {
    const MenuOptions = (row: any) => {
        return (
            <Menu items={[getItem("Editar", 1, () => {
                dispatch(setCurrentBanner(row));
                showModal();
            })]} />
        );
    };

    return ([
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            fixed: textLeft as "left",
            sorter: (a:any, b:any) => a.id - b.id,
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            sorter: (a:any, b:any) => a.name.localeCompare(b.name)
        },
        {
            title: "Descripción",
            dataIndex: "description",
            key: "description"
        },
        {
            title: "Link",
            dataIndex: "url",
            key: "url"
        },
        {
            title: "Imagen",
            dataIndex: "photo",
            key: "photo",
            fixed: textRight as "right",
            align: textCenter as "center",
            render: (text:any) => <Image image={text} preview={false} style={{ width: 250 }}/>
        },
        {
            title: "Acciones",
            key: "action",
            align: textCenter as "center",
            fixed: textRight as "right",
            width: "6em",
            render: (record: any) => {
                return (
                    <Dropdown overlay={MenuOptions(record)} trigger={["click"]}>
                        <MoreOutlined />
                    </Dropdown>
                );
            },
        }
    ]);
}

export const zonesColumns = (dispatch:any, navigate:any) => {
    const MenuOptions = (row: any) => {
        return (
            <Menu items={[getItem("Editar", 1, () => {
                dispatch(setCurrentZone(row));
                navigate(`/zones/${row.id}`);
            })]} />
        );
    };

    return ([
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            fixed: textLeft as "left",
            sorter: (a:any, b:any) => a.id - b.id,
        },
        {
            title: "Estado",
            dataIndex: "administrative_area_level_1",
            key: "administrative_area_level_1",
            render: (text:any) => text || "",
            sorter: (a:any, b:any) => (a.administrative_area_level_1 || "").localeCompare(b.administrative_area_level_1 || "")
        },
        {
            title: "Localidad",
            dataIndex: "locality",
            key: "locality"
        },
        {
            title: "Factor",
            dataIndex: "factor",
            key: "factor",
            fixed: textRight as "right",
            // align: textCenter as "center"
        },
        {
            title: "Acciones",
            key: "action",
            align: textCenter as "center",
            fixed: textRight as "right",
            width: "6em",
            render: (record: any) => {
                return (
                    <Dropdown overlay={MenuOptions(record)} trigger={["click"]}>
                        <MoreOutlined />
                    </Dropdown>
                );
            },
        }
    ]);
}
