import { Form } from "react-bootstrap";
import AntdDatePicker from "./AntdDatePicker";
import locals from 'antd/es/date-picker/locale/es_ES';

export declare type PanelMode = 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year' | 'decade';
export declare type PickerMode = Exclude<PanelMode, 'datetime' | 'decade'>;

interface DatePickerPropsI {
   label?: string;
   classNameLabel?: string;
   text?: string;
   classNameText?: string;
   controlId?: string;
   classNameContainer?: string;
   format?: string;
   locale?: any;
   style?: any;
   defaultValue?: any;
   onChange?: any;
   value?: any;
   clearIcon?: any;
   disabledInput?: boolean;
   disabledDate?: any;
   picker?: Exclude<PickerMode, 'time'>;
   placeholder?: string;
}

const DatePicker = ({
   label, classNameLabel, text, classNameText, controlId,
   classNameContainer, format,
   locale=locals, style, defaultValue, onChange, value, clearIcon, disabledInput,
   disabledDate, picker="date", placeholder="Seleccionar"
}: DatePickerPropsI) => {
   return (
        <Form.Group controlId={controlId} className={classNameContainer}>
            <Form.Label className={classNameLabel}>
                {label}
            </Form.Label>
            <AntdDatePicker
                locale={locale}
                style={style}
                defaultValue={defaultValue} format={format} onChange={onChange}
                value={value}
                clearIcon={clearIcon}
                disabled={disabledInput}
                disabledDate={disabledDate}
                picker={picker}
                placeholder={placeholder}
            />
            <Form.Text className={classNameText}>
                {text}
            </Form.Text>
        </Form.Group>
    )
}
export default DatePicker
