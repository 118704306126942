import { ProgressBar } from 'components'
import { Fragment } from 'react'
import { Row, Col } from 'react-bootstrap'

interface StepsWrapperProps {
   percentBar: number
   active: number
   steps: Array<any>
}

const StepsWrapper = ({ percentBar, steps, active }:StepsWrapperProps) => {
   return (
      <Fragment>
         <ProgressBar now={percentBar} className='pb-dash-steps' />
         <Row>
            {steps.map((item, index) => (
               <Col key={index}>
                  <p className={`${active >= Number(item.id) ? 'active-p' : 'inactive-p'}`}>
                     {/* <span style={{ marginRight: '0.3em'}}><Logo image={active >= Number(item.id) ? Assets.iconsImgs[`isact${item.icon}`] : Assets.iconsImgs[`is${item.icon}`]} className='notRound' /></span> */}
                     {item.text}
                  </p>
               </Col>
            ))}
         </Row>
      </Fragment>
   )
}
export default StepsWrapper