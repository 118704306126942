import { Button, Divider, Input, Modal } from "components";
import { useEffect, useState } from "react";
import { Col, Form, Modal as M, Row } from "react-bootstrap";
import styles from "pages/settings/GoodLooks.module.css";
import { useAppSelector } from "hooks";

interface CountryFormPropsI {
    okHandler: (object:any) => void;
    visible: boolean;
    closeModal: () => void;
}

const CountryForm = ({okHandler, visible, closeModal}:CountryFormPropsI) => {
    const [name, setName] = useState("");
    const [factor, setFactor] = useState("");
    const [currency, setCurrency] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);

    const { country } = useAppSelector(({catalogs}) => catalogs);

    const handleSubmit = (e:any) => {
        e.preventDefault();
        okHandler({
            country: {
                name,
                currency,
                factor
            }
        });
    }

    const handleChange = ({ target}:any) => {
        const {name, value} = target;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "factor":
                setFactor(value);
                break;
            case "currency":
                setCurrency(value);
                break;
            default:
                return null;
        }
    }

    const handleClearState = () => {
        setName("");
        setFactor("");
        setCurrency("");
    }

    const handleHideModal = () => {
        handleClearState();
        closeModal();
    }

    const validations = () => {
        if (name && factor && currency)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }

    useEffect(() => {
        validations();
    }, [name, factor, currency])

    useEffect(() => {
        if (Object.keys(country).length > 0) {
            setName(country.name);
            setCurrency(country.currency);
            setFactor(country.factor !== undefined ? country.factor.toString() : "");
        }
    }, [])

    return (
        <Modal show={visible} centered
            onHide={handleHideModal} backdrop="static" keyboard={false}
        >
            <M.Header closeButton>
                <h6 className="m-0">{`${Object.keys(country).length > 0 ? "Editar" : "Agregar"} País`}</h6>
            </M.Header>
            <M.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Input
                                id="name"
                                name="name"
                                classNameContainer="mb-2"
                                label="Nombre"
                                value={name}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Input
                                id="factor"
                                name="factor"
                                classNameContainer="mb-2"
                                label="Factor"
                                value={factor}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Input
                                id="currency"
                                name="currency"
                                classNameContainer="mb-2"
                                label="Moneda"
                                value={currency}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Divider style={{ margin: "16px 0"}} />
                    <Row>
                        <Col className={styles["col-form"]} xs={12} sm={12} md={{ offset: 4, span: 8}} lg={{ offset: 6, span: 6}}>
                            <Button size="sm" className="me-3 pry-outline-btn" onClick={handleHideModal}>
                                {"Cancelar"}
                            </Button>
                            <Button size="sm" className="primary-btn" type="submit" disabled={isDisabled}>
                                {"Enviar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </M.Body>
        </Modal>
    )
}
export default CountryForm;