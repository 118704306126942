import dayjs from "dayjs";
import api from "./api";

export const returningError = (error:any) => {
    if (error?.response) {
        const err = typeof(error?.response?.data) !== "object" ?
            error?.response?.data
            :
            error?.response?.data?.errors[0] || JSON.stringify(error?.response?.data?.errors).replaceAll(/[{}]|\]|\[|"|status|/gi, "");
        return {
            data: '',
            errors: err,
            status: error?.response?.status,
            success: "false"
        };
    }
    return {
        data: '',
        errors: error.message,
        status: 500,
        success: "false"
    };
}

export const getActiveServices = async () => {
    try {
        const { data }:any = await api.get("/es/v1/reports/active_sales");
        return data;
    } catch (error:any) {
        return returningError(error);
    }
}

export const getAllHunters = async () => {
    try {
        const { data }:any = await api.get("/es/v1/hunters");
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const getEvents = async () => {
    try {
        const { data }:any = await api.get("/es/v1/promocodes");
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const getHistoricalServices = async () => {
    try {
        const { data }:any = await api.get("/es/v1/sales");
        return data;
    } catch (error:any) {
        return returningError(error);
    }
}

export const getServiceDetail = async (id:any) => {
    try {
        const { data }:any = await api.get(`/es/v1/sales/${id}`);
        return data;
    } catch (error:any) {
        return returningError(error);
    }
}

export const getTodayServices = async () => {
    try {
        const date = dayjs().format("YYYY-MM-DD");
        const { data }:any = await api.get(`/es/v1/reports/sales_date/${date}`);
        return data;
    } catch (error:any) {
        return returningError(error);
    }
}

export const postBanner = async (body:any) => {
    try {
        const { data }:any = await api.post(`/es/v1/banners`, body);
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const postCategory = async (body:any) => {
    try {
        const { data }:any = await api.post("/es/v1/categories", body);
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const postCountry = async (body:any) => {
    try {
        const { data }:any = await api.post("/es/v1/countries", body);
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const postEmployee = async (body:any) => {
    try {
        const { data }:any = await api.post("/es/v1/employees", body);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const postEvents = async (body:any) => {
    try {
        const { data }:any = await api.post("/es/v1/promocodes", body);
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const postHunter = async (body:any) => {
    try {
        const { data }:any = await api.post("/es/v1/hunters", body);
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const postProduct = async (body:any) => {
    try {
        const { data }:any = await api.post("/es/v1/products", body);
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const postZone = async (body:any) => {
    try {
        const { data }:any = await api.post("/es/v1/zones", body);
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const putBanner = async (id:any, body:any) => {
    try {
        const { data }:any = await api.put(`/es/v1/banners/${id}`, body);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const putCategory = async (id:any, body:any) => {
    try {
        const { data }:any = await api.put(`/es/v1/categories/${id}`, body);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const putCountry = async (id:any, body:any) => {
    try {
        const { data }:any = await api.put(`/es/v1/countries/${id}`, body);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const putEmployee = async (id:any, body:any) => {
    try {
        const { data }:any = await api.put(`/es/v1/employees/${id}`, body);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const putEvent = async (id:any, body:any) => {
    try {
        const { data }:any = await api.put(`/es/v1/promocodes/${id}`, body);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const putHunter = async (id:any, body:any) => {
    try {
        const { data }:any = await api.put(`/es/v1/hunters/${id}`, body);
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const putProduct = async (id:any, body:any) => {
    try {
        const { data }:any = await api.put(`/es/v1/products/${id}`, body);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const putSale = async (id:any, body:any) => {
    try {
        const { data }:any = await api.put(`/es/v1/sales/${id}`, body);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const putZone = async (id:any, body:any) => {
    try {
        const { data }:any = await api.put(`/es/v1/zones/${id}`, body);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const locateZone = async (body:any) => {
    try {
        const { data }:any = await api.get(`/es/v1/locate?locality=${body.locality}&administrative_area_level_1=${body.aal1}&administrative_area_level_2=${body.aal2}&sublocality=${body.sublocality}&neighborhood=${body.neighborhood}`);
        return data;
    } catch (error:any) {
        return returningError(error);
    }
}

export const getProductsByZone = async (body:any) => {
    try {
        const { data }:any = await api.get(`/es/v1/products?raw=${body.raw}&zone=${body.zone}`);
        return data;
    } catch (error:any) {
        return returningError(error);
    }
}

export const postSale = async (body:any) => {
    try {
        const { data }:any = await api.post(`/es/v1/sales_new`, body);
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}


