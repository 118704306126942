import { Empty, Transfer } from "antd";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

interface ZonesPropsI {
    zonesArr?:any;
    setRequest: (obj:any) => void;
}

const ZonesForm = ({ zonesArr, setRequest }:ZonesPropsI) => {
    const [targetKeys, setTargetKeys] = useState([]);
    const { zones } = useAppSelector(({ catalogs }) => catalogs);

    const renderItem = (item:any) => {
        return {
            label: item.title || "null", // for displayed item
            value: item.id, // for title and filter matching
        };
    }

    const handleChange = (keys:any) => {
        setTargetKeys(keys);
    }

    useEffect(() => {
        if (zonesArr) {
            setTargetKeys(zonesArr.map((item:any) => item.zone_id.toString()))
        }
    },[])

    useEffect(() => {
        if (targetKeys.length > 0) {
            setRequest({zones: targetKeys.map((item) => ({ zone_id: item }))});
        } else {
            setRequest({zones: targetKeys});
        }
    },[targetKeys])

    return (
        <Form>
            <Transfer
                titles={["Zonas", "Zonas Seleccionadas"]}
                locale={{
                    itemUnit: "zonas",
                    itemsUnit: "zonas",
                    notFoundContent: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No hay datos"
                        />
                    )
                }}
                dataSource={zones.data ? zones.data.map((item:any) => ({
                    key: item.id.toString(),
                    title: `${item.administrative_area_level_1}${item.locality ? ` - ${item.locality}` : ""}`,
                    description: item.locality,
                })) : []}
                listStyle={{
                    width: "45%",
                    height: 350,
                }}
                onChange={handleChange}
                render={renderItem}
                targetKeys={targetKeys}
            />
        </Form>
    )
}
export default ZonesForm;