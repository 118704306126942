import { AvatarUpload, Button, Form, Input, InputMask, InputPassword, RadioGroup, TextArea } from "components";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { genderEnum } from "utils/enums";
import styles from "pages/settings/GoodLooks.module.css";
import { useParams } from "react-router-dom";
import { useS3Upload, useValidation } from "hooks";
import { ucFirst } from "utils/formatter";
import { notification, Spin } from "antd";
import { s3config } from "utils/s3config";

interface UserFormPropsI {
    onSubmited: (object:any) => void;
    onCancel: () => void;
    employee?: any;
}

const PHONE_LENGTH = 10;
const MINIMUM_PASS_CHAR = 4;

const UserForm = ({ employee, onCancel, onSubmited }: UserFormPropsI) => {
    const [name, setName] = useState("");
    const [lastNames, setLastNames] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [confPassInvalid, setConfPassInvalid] = useState(false);
    const [gender, setGender] = useState("");
    const [note, setNote] = useState("");
    const [photo, setPhoto] = useState<any>("");
    const [photoBlob, setPhotoBlob] = useState("");
    const [photoS3, setPhotoS3] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);

    const { id } = useParams();
    const isEdit = id ? true : false;
    const { emailValidation, wordValidation } = useValidation();
    const { uploadingFile, isLoading } = useS3Upload();

    const uploadAwsS3 = async () => {
        try {
            const { success, location, error } = await uploadingFile(photo, { ...s3config, dirName: "profile" });
            if (success) {
                setPhotoS3(location);
            } else {
                setPhotoS3("");
                setPhotoBlob("");
                setPhoto("");
                notification.error({
                    message: "Error",
                    description: error || "Error en S3"
                });
            }
        } catch (error:any) {
            setPhotoS3("");
            setPhotoBlob("");
            setPhoto("");
            notification.error({
                message: "Error",
                description: error.message
            });
        }
    }

    const handleChange = ({ target }: any) => {
        const { id, value } = target;
        switch (id) {
            case "name":
                setName((prev) => wordValidation(value) ? ucFirst(value) : prev);
                break;
            case "lastname":
                setLastNames((prev) => wordValidation(value) ? ucFirst(value) : prev);
                break;
            case "email":
                setEmail(value.trim());
                break;
            case "pass":
                setPass(value);
                break;
            case "confirmpass":
                setConfirmPass(value);
                break;
            case "notes":
                setNote(value);
                break;
            case "gender":
                setGender(value);
                break;
            default:
                return null;
        } 
    }

    const handleChangeMaskedInput = (value:any) => {
        setPhone(value);
    }

    const handleGender = (e:any) => {
        const { value } = e.target;
        setGender(value);
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        onSubmited({
            email,
            gender,
            lastnames: lastNames,
            name,
            password: pass,
            phone: phone.replace(/ /g, ""),
            notes: note,
            device_token: "",
            qualification: 5,
            status: true,
            photo_uri: photoS3
        });
    }

    const fileValidation = (file:any) => {
        if (file.size < 500000)
            return true;
        notification.error({
            message: "Error",
            description: "La imagen excede el támaño permitido"
        });
        setPhoto("");
        setPhotoBlob("");
        return false
    }

    useEffect(() => {
        if (employee) {
            const {lastnames, notes} = employee;
            setName(employee.name);
            setLastNames(lastnames || "");
            setEmail(employee.email || "");
            setPhone(employee.phone ? employee.phone.replace(/ |\(|\)/gi, "") : "");
            setGender(employee.gender|| "");
            setNote(notes || "");
            setPhotoBlob(employee?.photo_uri || '');
            setPhotoS3(employee?.photo_uri || '');
        }
    },[employee])

    useEffect(() => {
        if (photo)
            uploadAwsS3();
    }, [photo])

    useEffect(() => {
        if (!pass && !confirmPass) {
            setConfPassInvalid(false);
        }

        if (pass && confirmPass && pass) { 
            if (pass !== confirmPass) {
                setConfPassInvalid(true);
            } else {
                setConfPassInvalid(false);
            }
        }

        if (!id) {
            if (name && lastNames && emailValidation(email) && phone.replace(/ /g, "").length === PHONE_LENGTH &&
                pass === confirmPass && pass.length >= MINIMUM_PASS_CHAR && gender && photoS3) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }
        } else {
            onSubmited({
                email,
                gender,
                lastnames: lastNames,
                name,
                password: pass,
                phone: phone.replace(/ /g, ""),
                notes: note,
                confPass: confirmPass,
                device_token: "",
                qualification: 5,
                status: true,
                photo_uri: photoS3 ? photoS3 : employee?.photo_uri || ""
            });
        }
    }, [name, lastNames, email, phone, pass, confirmPass, gender, note, photoS3])

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col className="text-center" xs={12} sm={12} md={12} lg={12}>
                    <Spin spinning={isLoading}>
                        <AvatarUpload
                            accept="image/*"
                            beforeUpload={(file) => {
                                if (fileValidation(file)) {
                                    setPhoto(file);
                                    setPhotoBlob(URL.createObjectURL(file));
                                }
                                return false;
                            }}
                            showUploadList={false}
                            image={photoBlob}
                        />
                    </Spin>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <Input
                        id="name"
                        name="name"
                        classNameContainer="mb-2"
                        label="Nombre(s)"
                        value={name}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <Input
                        id="lastname"
                        name="lastname"
                        classNameContainer="mb-2"
                        label="Apellidos"
                        value={lastNames}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <Input
                        id="email"
                        name="email"
                        classNameContainer="mb-2"
                        label="Correo Electrónico"
                        value={email}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <InputMask
                        id="phone"
                        name="phone"
                        classNameContainer="mb-2"
                        label="Teléfono"
                        value={phone}
                        mask="99 9999 9999"
                        onChange={handleChangeMaskedInput}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <InputPassword
                        id="pass"
                        name="pass"
                        classNameContainer="mb-2"
                        label="Contraseña"
                        value={pass}
                        onChange={handleChange}
                        text={`La contraseña debe tener al menos ${MINIMUM_PASS_CHAR} caracteres`}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <InputPassword
                        id="confirmpass"
                        name="confirmpass"
                        classNameContainer="mb-2"
                        label="Confirmar Contraseña"
                        value={confirmPass}
                        onChange={handleChange}
                        text={confPassInvalid ? "Las contraseñas no coinciden" : ""}
                        classNameText={confPassInvalid ? "wrong-text" : ""}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <RadioGroup
                        id="gendergroup"
                        name="gendergroup"
                        classNameContainer="mb-2"
                        radioOptions={genderEnum} label="Genero" classNameLabel="w-100"
                        value={gender}
                        onChange={handleGender}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <TextArea
                        id="notes"
                        name="notes"
                        classNameContainer="mb-2"
                        classNameLabel="w-100"
                        label="Notas"
                        value={note}
                        onChange={handleChange}
                    />
                </Col>
                {!isEdit &&
                <Col className={styles["col-form"]} xs={12} sm={12} md={{ offset: 4, span: 8}} lg={{ offset: 6, span: 6}}>
                    <Button size="sm" className="me-5 pry-outline-btn" onClick={onCancel}>
                        {"Cancelar"}
                    </Button>
                    <Button size="sm" className="primary-btn" type="submit" disabled={isDisabled}>
                        {"Enviar"}
                    </Button>
                </Col>
                }
            </Row>
        </Form>
    )
}
export default UserForm;
