import { ReactNode } from "react";
import { Form as BootstrapForm, FormProps } from "react-bootstrap";

interface FormPropsI extends FormProps {
    children?: ReactNode | undefined;
}

const Form = ({ children, ...rest }:FormPropsI) => {
    return (
        <BootstrapForm {...rest}>
            {children}
        </BootstrapForm>
    )
}
export default Form;
