import { Switch } from "antd";
import { Divider, Form, InputNumber } from "components"
import { useAppSelector } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Geosuggest from "@ubilabs/react-geosuggest";
import styles from "pages/settings/GoodLooks.module.css";

interface ZoneFormPropsI {
    setRequest: (obj:any) => void;
}

const COUNTRY_MX = "MX";
const ZoneForm = ({ setRequest }: ZoneFormPropsI) => {
    // const inputRef = useRef<Geosuggest>(null);
    const [name, setName] = useState("");
    const [factor, setFactor] = useState("");
    const [viatic, setViatic] = useState("");
    const [modified, setModified] = useState(false);
    const [zoneSelected, setZoneSelected] = useState<any>(undefined);
    const [address, setAddress] = useState<any>(undefined);
    const { zone } = useAppSelector(({ catalogs }) => catalogs);

    const getProperties = (prop:string, shortName=false) => {
        if (address)
            return address.find((item:any) => item.types.includes(prop))?.[shortName ? "short_name" : "long_name"] || "";
        return "";
    }
    
    const handleSearch = (value:any) => {
        setName(value);
        setZoneSelected(undefined);
        setAddress(undefined);
    }

    useEffect(() => {
        if (Object.keys(zone).length > 0) {
            setFactor(zone.factor);
            setViatic(zone.travelers || 0);
        }
    }, [zone])

    useEffect(() => {
        if (zoneSelected) {
            const { gmaps } = zoneSelected;
            setName("");
            setAddress(gmaps.address_components);
        }
    }, [name, zoneSelected, factor])

    useEffect(() => {
        if (address) {
            setRequest({
                factor,
                administrative_area_level_1: getProperties("administrative_area_level_1") || "",
                administrative_area_level_2: getProperties("administrative_area_level_2") || "",
                locality: getProperties("locality"),
                neighborhood: getProperties("country", true) === COUNTRY_MX ? getProperties("sublocality_level_1") : getProperties("sublocality"),
                sublocality: "",
                travelers: viatic
            })
        } else {
            setRequest({
                factor,
                administrative_area_level_1: zone.administrative_area_level_1,
                administrative_area_level_2: zone.administrative_area_leve2,
                locality: zone.locality,
                neighborhood: zone.neighborhood,
                sublocality: zone.sublocality,
                travelers: viatic
            });
        }
    }, [address, factor, viatic])

    return (
        <Form>
            <Row>
                <Col>
                    <p className="mb-2"><span><Switch checked={modified} onChange={() => setModified(!modified)}/></span>  ¿Deseas cambiar zona?</p>
                    {modified &&
                        <Geosuggest
                            inputType="search"
                            inputClassName="ant-input"
                            suggestItemClassName={styles["list-google"]}
                            types={["geocode"]}
                            placeholder="Ingresa la Zona"
                            onSuggestSelect={(suggest:any) => {
                                setZoneSelected(suggest);
                            }}
                            renderSuggestItem={(suggest) => {
                                const { description } = suggest;
                                return (
                                    <Fragment>
                                            {name &&
                                            <div>
                                                <p className="m-0">Dirección:</p>
                                                <p className="m-0">{description}</p>
                                                <Divider style={{ margin: "8px 0"}} />
                                            </div>}
                                    </Fragment>
                                )
                            }}
                            onChange={handleSearch}
                        />
                    }
                </Col>
                <Col>
                    <h6>Información Zona</h6>
                    {!address &&
                    <Fragment>
                        <p>{`Colonia: ${zone.neighborhood || ""}`}</p>
                        <p>{`Ciudad: ${zone.locality || ""}`}</p>
                        <p>{`Estado: ${zone.administrative_area_level_1 || ""}`}</p>
                    </Fragment>}
                    {address &&
                    <Fragment>
                        <p>{`Colonia: ${getProperties("sublocality_level_1")}`}</p>
                        <p>{`Código Postal: ${getProperties("postal_code")}`}</p>
                        <p>{`Ciudad: ${getProperties("locality")}`}</p>
                        <p>{`Estado: ${getProperties("administrative_area_level_1")}`}</p>
                        <p>{`País: ${getProperties("country")}`}</p>
                    </Fragment>}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                            <InputNumber
                                label="Factor"
                                value={factor}
                                min={0}
                                onChange={(value:any) => setFactor(value)}
                                classNameLabel="w-100"
                                style={{ width: 250}}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                            <InputNumber
                                label="Viaticos"
                                value={viatic}
                                min={0}
                                onChange={(value:any) => setViatic(value)}
                                classNameLabel="w-100"
                                style={{ width: 250}}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}
export default ZoneForm;