import { Radio, RadioGroupProps } from 'antd';
import { Form } from "react-bootstrap";

interface RadioGroupPropsI extends RadioGroupProps {
    radioOptions: { value: any; title: string }[];
    label?: string | JSX.Element | JSX.Element[];
    classNameLabel?: string;
    controlId?: string;
    classNameContainer?: string;
}

const RadioGroup = ({radioOptions=[], label, classNameLabel, controlId, classNameContainer, ...props}:RadioGroupPropsI) => {
    return (
        <Form.Group controlId={controlId} className={classNameContainer}>
            <Form.Label className={classNameLabel}>
                {label}
            </Form.Label>
            <Radio.Group {...props}>
                {radioOptions.map((item, index) => (
                    <Radio key={index} value={item.value}>{item.title}</Radio>
                ))}
            </Radio.Group>
            <Form.Text />
        </Form.Group>
    );
}
export default RadioGroup;