// import { Modal as AntdModal, ModalProps } from "antd";
import { FC } from "react";
import { Modal as BootstrapModal, ModalProps } from 'react-bootstrap';

interface MProps extends ModalProps {
   children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}

const Modal:FC<MProps> = ({ children, ...rest}) => 
   <BootstrapModal {...rest}>
      {children}
   </BootstrapModal>
export default Modal
