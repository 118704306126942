
import { Empty, notification} from "antd";
import { getServiceDetail, putSale } from "api";
import { Collapse, InternalPageLayout, Panel } from "components";
import dayjs from "dayjs";
import es from "dayjs/locale/es";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddressDetail, WhenServiceDetail } from "./detail";

dayjs.locale(es);

// global constants 
const WHEN_DATA = "1";
const CONTACT_DATA = "2";

type AddressType = {
    adminstrative_area_level_1?: any;
    adminstrative_area_level_2?: any;
    alias: string;
    details: string;
    id: number;
    latitude: string;
    locality: string;
    longitude: string;
    neighborhood: string;
    number: string;
    street: string;
    sublocality: string;
    zipcode: string;
    zone_id: string | number;
}

type ItemsType = {
    id: number;
    price: number | string;
    product: {
        category_id: number;
        description: string;
        duration: number | string;
        id: number;
        name: string;
        order: number;
        photo: string;
        sell_price: number;
        status: boolean;
    };
    quantity: number;   
}

interface ServiceDataPropsI {
    address: AddressType;
    date: string;
    date_arriving?: string;
    date_assigned?: string;
    date_finished?: string;
    date_new?: string;
    date_ongoing?: string;
    employee?: string;
    id: number;
    items: Array<ItemsType>;
    notes?: string;
    payment_id: string;
    payment_type: string;
    price: number | string;
    promo_code?: any;
    status: string;
}

const ServiceDetail = () => {
    const [service, setService] = useState<ServiceDataPropsI>();
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const { currentService } = useAppSelector(({ services }) => services);
    const navigate = useNavigate();

    const gettingServiceDetail = async () => {
        if (!isLoading)
            setIsLoading(true);
        try {
            const { data, errors } = await getServiceDetail(id);
            if (data) {
                setService(data);
            } else {
                setService(undefined);
                notification.error({
                    message: "Detalle de Historico",
                    description: errors
                });
            }
            setIsLoading(false);
        } catch (error:any) {
            setService(undefined);
            setIsLoading(false);
            notification.error({
                message: "Listado de Historico",
                description: error.message
            });
        }
    }

    const updatingSale = async (body:any) => {
        if (!isLoading) setIsLoading(true);
        try {
            const request = {
                sale: {
                    ...body,
                    latitude: service?.address.latitude,
                    longitude: service?.address.longitude,
                    street: service?.address.street || '',
                    number: service?.address.number || '',
                    neighborhood: service?.address.neighborhood || '',
                    zipcode: service?.address.zipcode || '',
                    locality: service?.address.locality || ''
                }
            }
            const { success, errors } = await putSale(id, request);
            if (success === "true") {
                setIsLoading(false);
                notification.success({
                    message: "Actualización de Venta",
                    description: "La Actualización se realizó de Forma Exitosa"
                });
                navigate("/services/historical",{ replace: true });
            } else {
                setIsLoading(false);
                notification.error({
                    message: "Actualización de Venta",
                    description: errors
                });
            }
        } catch (error:any) {
            setIsLoading(false);
            notification.error({
                message: "Actualización de Venta",
                description: error.message
            });
        }
    }

    useEffect(() => {
        if (id) gettingServiceDetail();
    },[id])
    return (
        <InternalPageLayout title="Detalle de Servicio">
            <Collapse className="step-panel" defaultActiveKey={[WHEN_DATA, CONTACT_DATA]} expandIconPosition="right">
                <Panel header="Servicios" key={WHEN_DATA}>
                    {service && 
                    <WhenServiceDetail 
                        data={{
                            date: dayjs(service?.date).format('MMM DD, YYYY'),
                            hour: dayjs(service?.date).format('hh:mm A'),
                            items: service?.items || [],
                            promoCode: service?.promo_code,
                            status: currentService.status
                        }}
                        loading={isLoading}
                        sendingData={updatingSale}
                    />}
                    {!service &&
                        <Empty description={"No se encontro Información"} />
                    }
                </Panel>
                <Panel header="Cliente" key={CONTACT_DATA}>
                    {currentService.customer && service &&
                    <AddressDetail data={{
                        customer: currentService.customer || "",
                        email: currentService.email || "",
                        phone: currentService.phone || "",
                        alias: service?.address.alias || "",
                        street: service?.address.street || "",
                        number: service?.address.number || "",
                        neighborhood: service?.address.neighborhood || "",
                        zipcode: service?.address.zipcode || "",
                        locality: service?.address.locality || "",
                    }} />}
                    {!service &&
                        <Empty description={"No se encontro Información"} />
                    }
                </Panel>
            </Collapse>
        </InternalPageLayout>
    )
}
export default ServiceDetail;
