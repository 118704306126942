export const statusEnum = [
    {
        id:6,
        label: "Nuevo",
        value: "new"
    },
    {
        id:7,
        label: "Asignado",
        value: "assigned"
    },
    {
        id:1,
        label: "Llegando",
        value: "arriving"
    },
    {
        id:2,
        label: "En Proceso",
        value: "ongoing"
    },
    {
        id:5,
        label: "Finalizado",
        value: "finished"
    },
    {
        id:3,
        label: "Cancelado GL",
        value: "canceledGood"
    },
    {
        id:4,
        label: "Cancelado Usuario",
        value: "canceledUser"
    },
];

export const genderEnum = [
    {
        id: 1,
        value: "M",
        title: "Masculino"
    },
    {
        id: 1,
        value: "F",
        title: "Femenino"
    }
];

export const activeEnum = [
    {
        id:1,
        label: "Activo",
        value: "active"
    },
    {
        id: 2,
        label: "Inactivo",
        value: "inactive"
    }
];

export const currencyEnum = [
    {
        id:1,
        label: "MXN",
        value: "MXN"
    },
    {
        id: 2,
        label: "USD",
        value: "USD"
    }
];

export const typeEnum = [
    {
        label: "Boda",
        value: "wedding"
    },
    {
        label: "Cumpleaños",
        value: "birthday"
    },
    {
        label: "Graduación",
        value: "graduation"
    },
    {
        label: "Aniversario",
        value: "anniversary"
    },
    {
        label: "Otro",
        value: "other"
    }
];