import { notification } from "antd";
import { Button, Card, Form, Input, InputPassword } from "components";
import { useAppSelector, useValidation } from "hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSignIn } from "store/user/user_t";

const MINIMUM_PASS_CHAR = 4;

const SignIn = () => {
    // redux constants
    const dispatch = useDispatch();
    const { status, errorMessage } = useAppSelector(({user}) => user);
    // state components constans
    const [email, setEmail] = useState("");
    const [emailInvFormat, setEmailInvFormat] = useState(false);
    const [password, setPassword] = useState("");
    const [passInvFormat, setPassInvFormat] = useState(false);
    // const [isSignIn, setIsSignIn] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate();
    const { emailValidation } = useValidation();
    // events handler
    const handleSubmit = (e:any) => {
        e.preventDefault();
        // if (isSignIn)
        userLogin();
    }

    const userLogin = async () => {
        try {
            dispatch(userSignIn({ email, password }));
        } catch (error:any) {
            console.log(error);
        }
    } 

    // const handleSwitchForm = () => {
    //     setIsSignIn(!isSignIn);
    // }

    // const restoreState = () => {
    //     setEmail("");
    //     setPassword("");
    // }

    // const handleBackSignIn = () => {
    //     restoreState();
    //     handleSwitchForm();
    // }

    const handleChange = (e:ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        if (id === "email") {
            setEmail(value.toLowerCase().trim());
        } else {
            setPassword(value.trim());
        }
    }
    // validations
    const validations = () => {
        // if (isSignIn) {
            if (emailValidation(email) && password.length >= MINIMUM_PASS_CHAR)
                setIsDisabled(false);
            else
                setIsDisabled(true);

            if (!password || password.length >= MINIMUM_PASS_CHAR)
                setPassInvFormat(false);
            else
                setPassInvFormat(true);
        // } else {
        //     if (emailValidation(email))
        //         setIsDisabled(false);
        //     else
        //         setIsDisabled(true);
        // }

        if (!email || emailValidation(email)) {
            return setEmailInvFormat(false);
        }
        setEmailInvFormat(true);
    }

    // actions
    const actionsSelector = () => {
        if (status.state === "SUCCESS") {
            navigate('/home');
        }
        if (status.state === "ERROR") {
            notification.error({
                message: "Inicio de Sesión",
                description: errorMessage
            });
        }
    }
    // effects handler
    useEffect(() => {
        validations();
    }, [email, password/*, isSignIn */])

    useEffect(() => {
        actionsSelector();
    }, [status])

    return (
        <Card>
            <Form onSubmit={status.fetching ? undefined : handleSubmit}>
                {/* <h6>{isSignIn ? "Iniciar Sesión" : "Recupera tu Contraseña"}</h6> */}
                <h6>{"Iniciar Sesión"}</h6>
                <Input classNameContainer="mb-3" label="Correo Electrónico" placeholder="Correo Electrónico" size="large"
                    id="email"
                    value={email}
                    onChange={handleChange}
                    text={emailInvFormat ? "Formato de Correo Invalido" : ""}
                    classNameText={emailInvFormat ? "wrong-text" : ""}
                    autoFocus
                />
                {/* {isSignIn &&
                <Fragment> */}
                <InputPassword classNameContainer="mb-3" label="Contraseña" placeholder="Contraseña" size="large"
                    id="password"
                    value={password}
                    onChange={handleChange}
                    text={passInvFormat ? `La contraseña debe tener al menos ${MINIMUM_PASS_CHAR} caracteres` : ""}
                    classNameText={passInvFormat ? "wrong-text" : ""}
                />
                {/* <Row className="g-0 mb-3">
                    <Col className="text-end" sm={12} md={{ offset: 4, span: 8}} lg={{ offset: 6, span: 6 }}>
                        <Button
                            className="p-0"
                            variant="link"
                            onClick={handleBackSignIn}
                        >
                            {"¿Olvidaste tu contraseña?"}
                        </Button>
                    </Col>
                </Row> */}
                {/* </Fragment>} */}
                <Button
                    className="mb-2 primary-btn" 
                    type="submit"
                    style={{ width: "100%" }}
                    disabled={isDisabled}
                    loading={status.fetching}
                >
                    {/* {isSignIn ? "Entrar" : "Recuperar"} */}
                    {"Entrar"}
                </Button>
                {/* {!isSignIn &&
                <div>
                    <Button
                        className="p-0"
                        variant="link"
                        onClick={handleBackSignIn}
                    >
                        {"Volver al Inicio de Sesión"}
                    </Button>
                </div>} */}
            </Form>
        </Card>
    )
}
export default SignIn;
