import { Empty, notification } from "antd";
import esES from 'antd/lib/locale/es_ES';
import { getEmployees } from "api";
import { CommonSearchForm, InternalPageLayout, Table } from "components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { goodLooksColumns } from "utils/tableColumns";

const GoodLooks = () => {
    const [employees, setEmployees] = useState([]);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const gettingEmployees = async () => {
        try {
            if (!isLoading) setIsLoading(true);
            const { data, success, errors } = await getEmployees();
            if (success === "true") {
                setEmployees(data);
            } else {
                setEmployees([]);
                notification.error({
                    message: "Listado de GoodLookeros",
                    description: errors
                });
            }
            setIsLoading(false);
        } catch (error:any) {
            setIsLoading(false);
            notification.error({
                message: "Listado de GoodLookeros",
                description: `Ocurrio un Error. ${error.message}`
            });
        }
    }

    const filterEmployees = () => {
        return employees.filter((item:any) => {
            const { name, lastnames, phone, email } = item;
            const tempName = name ? name.toLowerCase() : '';
            const tempLName = lastnames ? lastnames.toLowerCase() : '';
            const tempPhone = phone ? phone.replace(/ |\(|\)/gi, "") : '';
            const tempEmail = email ? email.toLowerCase() : '';
            return tempName.match(search.toLowerCase()) || tempLName.match(search.toLowerCase()) || tempPhone.match(search.replace(/ /g, "")) || tempEmail.match(search.toLowerCase())
        });
    }

    useEffect(() => {
        gettingEmployees();
    },[])

    return (
        <InternalPageLayout title="GoodLookeros" >
            <CommonSearchForm
                clickAdd={() => navigate("/goodlooks/add")}
                search={search}
                onSearch={({ target }:any) => setSearch(target.value.trimStart())}
            />
            <Table
                localeProv={esES}
                columns={goodLooksColumns(navigate)}
                dataSource={search ? filterEmployees() : employees}
                size="small"
                rowKey={'id'}
                locale={{
                    triggerDesc: 'Ordenar de forma descendente',
                    triggerAsc: 'Ordenar de forma ascendente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No hay datos"
                    />
                    )
                }}
                scroll={{ x: 'auto' }}
                pagination={{ defaultPageSize: 10 }}
                loading={isLoading}
            />
        </InternalPageLayout>
    )
}
export default GoodLooks;
