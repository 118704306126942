import { Upload, UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Form } from 'react-bootstrap';
import { Image } from 'components';

const { Dragger } = Upload;

interface DraggerPropsI extends UploadProps {
    label?: string | JSX.Element | JSX.Element[];
    classNameLabel?: string;
    text?: string;
    classNameText?: string;
    controlId?: string;
    classNameContainer?: string;
    image?: any;
    classNameImage?: string;
}

const DragDrop = ({ label, classNameLabel, text, classNameText, controlId,
    classNameContainer, classNameImage, image, ...rest }: DraggerPropsI) => (
    <Form.Group controlId={controlId} className={classNameContainer}>
        <Form.Label className={classNameLabel}>
            {label}
        </Form.Label>
        <Dragger {...rest} >
            {!image ? <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>: <Image image={image} preview={false} className={classNameImage} />}
            <p className="ant-upload-text">Click o arrastra la Imagen</p>
        </Dragger>
        <Form.Text className={classNameText}>
            {text}
        </Form.Text>
    </Form.Group>
);
export default DragDrop;
