import { FC, useContext, useState } from "react";
import AppSiderContext from "./AppSiderContext";

interface ASProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}

const ASProvider:FC<ASProps> = ({children}) => {
    const apContext = useContext(AppSiderContext);
    const [collapse, setCollapse] = useState(apContext.collapse);

    const handleCollapse = () => {
        setCollapse(!collapse);
    }

    return (
        <AppSiderContext.Provider value={{ collapse, onCollapse: handleCollapse }}>
            {children}
        </AppSiderContext.Provider>
    )
}
export default ASProvider;