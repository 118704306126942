import { Empty } from "antd";
import { InternalPageLayout, TabPane, Tabs } from "components";
import { useAppSelector } from "hooks";
import ProductsList from "./Products";
import { useEffect, useState } from "react";

const Products = () => {
    const { categories } = useAppSelector(({ catalogs }) => catalogs);

    const { data } = categories;

    const [workData, setWorkData] = useState<any>([]);

    const sortById = (a:any, b:any) =>{
        return a.id < b.id ? 1 : -1
    }

    useEffect(()=>{
        if(data.length > 0){
            setWorkData([...data]);
        }
    },[data]);

    return (
        <InternalPageLayout title="Productos" >
            {workData.length > 0 &&
            <Tabs defaultActiveKey={workData[0].id.toString()}>
                {workData.sort(sortById).map((item:any) => (
                    <TabPane tab={item.name.es} key={item.id.toString()}>
                        <ProductsList id={item.id.toString()} />
                    </TabPane>
                ))}
            </Tabs>}
            {workData.length === 0 &&
                <Empty
                    description="No hay Datos"
                />
            }
        </InternalPageLayout>
    )
}
export default Products;
