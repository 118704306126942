import { ProgressBar, ProgressBarProps } from 'react-bootstrap'

interface ProgressBarComponentProps extends ProgressBarProps {
   className?: string
}

function ProgressBarComponent ({ className, ...rest }: ProgressBarComponentProps) {
   return (
      <ProgressBar className={className} {...rest} />
   )
}
export default ProgressBarComponent
