import { Dropdown as AntdDropdown, DropDownProps } from "antd";

interface PropsI extends DropDownProps {
    children: any;
}

const Dropdown = ({ children, ...rest }: PropsI) => {
    return (
        <AntdDropdown {...rest}>
            {children}
        </AntdDropdown>
    )
}
export default Dropdown;