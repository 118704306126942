import { Empty, notification } from "antd";
import esES from 'antd/lib/locale/es_ES';
import { postZone } from "api";
import { CommonSearchForm, InternalPageLayout, Table } from "components";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { zonesColumns } from "utils/tableColumns";
import ZoneModal from "./ZoneModal";
import { setZones } from "store/catalogs/catalogs_t";

const Zones = () => {
    const [search, setSearch] = useState("");
    const [modalShowed, setModalShowed] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { zones } = useAppSelector(({ catalogs }) => catalogs);
    const { data, status } = zones;

    const filterZones = () => {
        return data.filter((item:any) => {
            const { administrative_area_level_1, locality } = item;
            const tempArea = administrative_area_level_1 ? administrative_area_level_1.toLowerCase() : "";
            const tempLocality = locality ? locality.toLowerCase() : "";
            return tempArea.match(search.toLowerCase()) || tempLocality.match(search.toLowerCase());
        });
    }

    const onCloseModal = () => {
        setModalShowed(false);
    }

    const registerZone = async (body:any) => {
        try {
            const request = {
                zone: {
                    ...body,
                }
            };
            
            const { success, errors } = await postZone(request);
            if (success === "true") {
                dispatch(setZones());
                onCloseModal();
                notification.success({
                    message: "Registro de Zona",
                    description: "El registro se realizó de forma exitosa"
                });
            } else {
                notification.error({
                    message: "Registro de Zona",
                    description: errors
                });
            }
        } catch (error:any) {
            notification.error({
                message: "Registro de Zona",
                description: error.message
            });
        }
    }

    useEffect(() => {
        dispatch(setZones());
    }, [])

    return (
        <InternalPageLayout title={"Zonas"}>
            <CommonSearchForm
                clickAdd={() => setModalShowed(true)}
                search={search}
                onSearch={({ target }:any) => setSearch(target.value.trimStart())}
            />
            <Table
                dataSource={search ? filterZones() : data || []}
                loading={status.fetching}
                localeProv={esES}
                columns={zonesColumns(dispatch, navigate)}
                rowKey={"id"}
                size="small"
                locale={{
                    triggerDesc: 'Ordenar de forma descendente',
                    triggerAsc: 'Ordenar de forma ascendente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No hay datos"
                    />
                    )
                }}
                scroll={{ x: 'auto' }}
                pagination={{ defaultPageSize: 10 }}
            />
            {modalShowed &&
                <ZoneModal
                    closeModal={onCloseModal}
                    okHandler={registerZone}
                    visible={modalShowed}
                />
            }
        </InternalPageLayout>
    )
}
export default Zones;
