import { Image } from 'react-bootstrap'
interface LandingProps {
  image: any,
  className?: string,
  style?: any
  handleClick?: (obj?: any) => void;
}

function Logo({ image, className, style, handleClick }: LandingProps) {
   return (
      <Image style={style} className={className} rounded src={image} onClick={handleClick} />
   )
}
export default Logo
