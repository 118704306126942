import { Empty, Transfer } from "antd";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

interface ServicesPropsI {
    services?:any;
    setRequest: (obj:any) => void;
}

const ServicesForm = ({ services, setRequest }:ServicesPropsI) => {
    const [targetKeys, setTargetKeys] = useState([]);
    const { products } = useAppSelector(({ catalogs }) => catalogs);

    const renderItem = (item:any) => {
        return {
            label: item.title, // for displayed item
            value: item.id, // for title and filter matching
        };
    }

    const handleChange = (keys:any) => {
        setTargetKeys(keys);
    };

    useEffect(() => {
        if (services) {
            setTargetKeys(services.map((item:any) => item.product_id.toString()))
        }
    },[])

    useEffect(() => {
        if (targetKeys.length > 0) {
            const data:any = new Set(targetKeys); // filter duplicates
            const arrData = [...data]; // set into array
            setRequest({products: arrData.map((item:any) => ({ product_id: item }))}); // arr mapped into request
        } else {
            setRequest({products: targetKeys});
        }
    }, [targetKeys])

    return (
        <Form>
            <Transfer
                titles={["Productos", "Productos Seleccionados"]}
                locale={{
                    itemUnit: "productos",
                    itemsUnit: "productos",
                    notFoundContent: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No hay datos"
                        />
                    )
                }}
                dataSource={products.data ? products.data.map((item:any) => ({
                    key: item.id.toString(),
                    title: item.name.es,
                    description: item.description.es,
                })) : []}
                listStyle={{
                    width: "45%",
                    height: 350,
                }}
                onChange={handleChange}
                render={renderItem}
                targetKeys={targetKeys}
            />
        </Form>
    )
}
export default ServicesForm;