import { Layout } from "antd";
import { CSSProperties, FC } from "react";

interface FooterPropsI {
    style?: CSSProperties;
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}

const Footer:FC<FooterPropsI> = ({ children, style }) => {
    return (
        <Layout.Footer style={style}>
            {children}
        </Layout.Footer>
    )
}
export default Footer;
