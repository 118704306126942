import { Form } from "react-bootstrap";
import { Input } from 'antd';

interface MaskedProps {
   mask: string;
   value: string;
   label?: string | JSX.Element | JSX.Element[];
   className?: string;
   classNameLabel?: string;
   text?: string;
   classNameText?: string;
   controlId?: string;
   classNameContainer?: string;
   id?: string;
   name?: string;
   onChange?: any;
   isDisabled?: boolean;
}

function InputMask ({ mask,  value, label, className,
   classNameLabel, text, classNameText, controlId, classNameContainer,
   id, name, onChange, isDisabled }:MaskedProps) {

   const handleInputChange = (e: any) => {
      const rawValue = e.target.value.replace(/\D/g, ''); // Eliminar todo excepto los dígitos
      let maskedValue = '';
      let maskIndex = 0;
      let valueIndex = 0;
   
      while (maskIndex < mask.length && valueIndex < rawValue.length) {
         if (mask[maskIndex] === '9') {
            maskedValue += rawValue[valueIndex];
            valueIndex++;
         } else {
            maskedValue += mask[maskIndex];
         }
         maskIndex++;
      }
      if (onChange) {
         onChange(maskedValue);
      }
   };
   
   return (
      <Form.Group controlId={controlId} className={classNameContainer}>
         <Form.Label className={classNameLabel}>
            {label}
         </Form.Label>
         <Input
            id={id}
            name={name}
            value={value}
            className={className}
            onChange={handleInputChange}
            disabled={isDisabled}
         />
         <Form.Text className={classNameText}>
            {text}
         </Form.Text>
      </Form.Group>
   )
}
export default InputMask;
