import { Button, Divider, Dragger, Input, Modal } from "components";
import { useEffect, useState } from "react";
import { Col, Form, Modal as M, Row } from "react-bootstrap";
import styles from "pages/settings/GoodLooks.module.css";
import { s3config } from "utils/s3config";
import { useAppSelector, useS3Upload } from "hooks";
import { notification, Spin } from "antd";

interface BannerPropsI {
    okHandler: (object:any) => void;
    visible: boolean;
    closeModal: () => void;
}

const BannerFormModal = ({okHandler, visible, closeModal}:BannerPropsI) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [link, setLink] = useState("");
    const [photo, setPhoto] = useState<any>("");
    const [photoBlob, setPhotoBlob] = useState<any>("");
    const [s3Photo, setS3Photo] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);

    const { banner } = useAppSelector(({ catalogs }) => catalogs);
    const { isLoading, uploadingFile } = useS3Upload();

    const handleSubmit = (e:any) => {
        e.preventDefault();
        okHandler({
            banner: {
                name,
                description,
                url: link,
                photo: s3Photo
            }
        });
    }

    const handleChange = ({ target }:any) => {
        const {name, value} = target;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "link":
                setLink(value);
                break;
            default:
                return null;
        }
    }

    const handleClearState = () => {
        setName("");
        setDescription("");
        setPhoto("");
        setPhotoBlob("");
    }

    const handleHideModal = () => {
        handleClearState();
        closeModal();
    }

    const fileValidation = (file:any) => {
        if (file.size < 500000)
            return true;
        notification.error({
            message: "Error",
            description: "La imagen excede el támaño permitido"
        });
        setPhoto("");
        setPhotoBlob("");
        return false;
    }

    const formValidation = () => {
        if (name && description && s3Photo)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }

    const uploadAwsS3 = async () => {
        try {
            const { success, location, error } = await uploadingFile(photo, 
                {
                    ...s3config,
                    dirName: "banners"
                });
            if (success) {
                setS3Photo(location);
            } else {
                setS3Photo("");
                notification.error({
                    message: "Error",
                    description: error || "Error en S3"
                });
            }
        } catch (error:any) {
            setS3Photo("");
            notification.error({
                message: "Error",
                description: error.message
            });
        }
    }

    useEffect(() => {
        formValidation();
    }, [name, description, s3Photo])

    useEffect(() => {
        if (photo)
            uploadAwsS3();
    }, [photo])

    useEffect(() => {
        if (Object.keys(banner).length > 0) {
            setName(banner.name);
            setDescription(banner.description);
            setLink(banner.url);
            setPhotoBlob(banner.photo);
            setS3Photo(banner.photo);
        }
    }, [])

    return (
        <Modal show={visible} centered
            onHide={handleHideModal} backdrop="static" keyboard={false}
        >
            <M.Header closeButton>
                <h6 className="m-0">{"Agregar Banner"}</h6>
            </M.Header>
            <M.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Input
                                id="name"
                                name="name"
                                classNameContainer="mb-2"
                                label="Nombre"
                                value={name}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Input
                                id="description"
                                name="description"
                                classNameContainer="mb-2"
                                label="Descripción"
                                value={description}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Input
                                id="link"
                                name="link"
                                classNameContainer="mb-2"
                                label="Url"
                                value={link}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Spin spinning={isLoading}>
                                <Dragger
                                    label={"Foto"}
                                    classNameContainer="mb-2"
                                    accept=".jpg, .png, .jpeg"
                                    beforeUpload={(file) => {
                                        if (fileValidation(file)) {
                                            setPhoto(file);
                                            setPhotoBlob(URL.createObjectURL(file));
                                        }
                                        return false;
                                    }}
                                    showUploadList={false}
                                    image={photoBlob}
                                />
                            </Spin>
                        </Col>
                    </Row>
                    <Divider style={{ margin: "16px 0"}} />
                    <Row>
                        <Col className={styles["col-form"]} xs={12} sm={12} md={{ offset: 4, span: 8}} lg={{ offset: 6, span: 6}}>
                            <Button size="sm" className="me-3 pry-outline-btn" onClick={handleHideModal}>
                                {"Cancelar"}
                            </Button>
                            <Button size="sm" className="primary-btn" type="submit" disabled={isDisabled}>
                                {"Enviar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </M.Body>
        </Modal>
    )
}
export default BannerFormModal;