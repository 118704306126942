import { memo } from "react";
import { Col, Row } from "react-bootstrap";

type DataT = {
    customer: string;
    email: string;
    phone: string;
    alias: string;
    street: string;
    number: string;
    neighborhood: string;
    zipcode: string;
    locality: string;
}

interface AddressDetailPropsI {
    data: DataT
}

const AddressDetail = ({ data }:AddressDetailPropsI) => {
    const { customer, email, phone, alias, street, number, neighborhood, zipcode, locality } = data;
    return (
        <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
                <h6>{"Contacto"}</h6>
                <p>Nombre: {customer || ''}</p>
                <p>Teléfono: {phone || ''}</p>
                <p>Email: {email || ''}</p>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
                <h6>{"Dirección"}</h6>
                <p className="text-capitalize">Lugar: {alias || ''}</p>
                <p className="m-0">Calle: {street || ''}, número: {number || ''}</p>
                <p className="m-0">Colonia: {neighborhood || ''}</p>
                <p className="m-0">Código Postal: {zipcode || ''}</p>
                <p>Ciudad: {locality || ''}</p>
            </Col>
        </Row>
    )
}
export default memo(AddressDetail);
