import { Container, StatisticCard } from "components";
import { useAppSelector } from "hooks";
import { Col, Row } from "react-bootstrap";

const GoodLooks = () => {
    const { categories, countries, products, zones } = useAppSelector(({ catalogs }) => catalogs);

    return (
        <Container>
            <h1>{"Dashboard"}</h1>
            <Row>
                <Col className="mb-2" sm={12} md={4} lg={6}>
                    <StatisticCard
                        title="Países"
                        value={countries.data ? countries.data.length : 0}
                    />
                </Col>
                <Col className="mb-2" sm={12} md={4} lg={6}>
                    <StatisticCard
                        title="Zonas"
                        value={zones.data ? zones.data.length : 0}
                    />
                </Col>
                <Col className="mb-2" sm={12} md={4} lg={6}>
                    <StatisticCard
                        title="Categorías Activas"
                        value={categories.data ? 
                                categories.data.length === 0 ? 
                                    categories.data.length : categories.data.reduce((acc=0, curr:any) => {
                                        if (curr.status) {
                                            return Number(acc)+1;
                                        } else {
                                            return Number(acc);
                                        }
                                    }, 0)
                                : 0}
                    />
                </Col>
                <Col className="mb-2" sm={12} md={4} lg={6}>
                    <StatisticCard
                        title="Productos Activos"
                        value={products.data ? 
                            products.data.length === 0 ? 
                                products.data.length : products.data.reduce((acc=0, curr:any) => {
                                    if (curr.status) {
                                        return Number(acc)+1;
                                    } else {
                                        return Number(acc);
                                    }
                                }, 0)
                            : 0}
                    />
                </Col>
            </Row>
        </Container>
    )
}
export default GoodLooks;
