
import { Collapse, CollapsePanelProps } from 'antd';
import { FC } from 'react';

interface PanelProps extends CollapsePanelProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}

const Panel:FC<PanelProps> = ({children, ...props}) => {
    return (
        <Collapse.Panel {...props}>{children}</Collapse.Panel>
    )
}
export default Panel;
