import { Collapse as AntdCollapse, CollapseProps } from 'antd';
import { FC } from 'react';

interface CProps extends CollapseProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}

const Collapse:FC<CProps> = ({children, ...props}) => {
    return <AntdCollapse {...props}>{children}</AntdCollapse>
}
export default Collapse;