import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { Form } from "react-bootstrap";

interface InputPropsI extends TextAreaProps {
    // rows: number;
    label?: string | JSX.Element | JSX.Element[];
    classNameLabel?: string;
    text?: string;
    classNameText?: string;
    controlId?: string;
    classNameContainer?: string;
}

const TextArea = ({ label, classNameLabel, text,
    classNameText, controlId, classNameContainer, ...props}: InputPropsI) => {
    return (
        <Form.Group controlId={controlId} className={classNameContainer}>
            <Form.Label className={classNameLabel}>
            {label}
            </Form.Label>
            <Input.TextArea {...props} />
            <Form.Text className={classNameText}>
            {text}
            </Form.Text>
        </Form.Group>
    )
}
export default TextArea;