
import { Layout, Menu } from "antd";
import { useContext } from "react";
import AppSiderContext from "./AppSiderContext";
import { menus } from "./Lists";

const { Sider } = Layout;

const AppSider = () => {
    // state constants
    const { collapse, onCollapse } = useContext(AppSiderContext);

    const handleCollapse = () => {
        if (onCollapse) onCollapse();
    }

    return (
        <Sider
            theme="light"
            collapsible
            onCollapse={handleCollapse}
            collapsed={collapse}
            style={{
                overflow: "auto",
                height: "calc(100vh - 112px)",
                position: "sticky",
                left: 0,
                top: 64,
                bottom: 48
            }}
        >
            <Menu
                theme="light"
                mode="inline"
                items={menus}
            />
        </Sider>
    )
}
export default AppSider;
