import { Tabs as AntTabs, TabsProps } from "antd";

interface TabsPropsI extends TabsProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
}

const Tabs = ({ children, ...props }:TabsPropsI) => {
    return (
        <AntTabs {...props}>
            {children}
        </AntTabs>
    )
}
export default Tabs;