import { createContext } from "react";

interface SiderContextI {
    collapse: boolean;
    onCollapse?: () => void;
}

export const defaultData = {
    collapse: false
}

const AppSiderContext = createContext<SiderContextI>(defaultData);
export default AppSiderContext;
