import { Store } from "@reduxjs/toolkit";
import { signIn } from "api";
import { SignInRequestI } from "api/auth";
import { setUser, userIsFetching } from "./userSlice";

export const userSignIn = (body:SignInRequestI) => async (dispatch: Store["dispatch"]) => {
   try {
      dispatch(userIsFetching());
      const { success, data, errors } = await signIn(body);
      if (success === "true") {
         dispatch(setUser({ state: true, data }));
      } else {
         dispatch(setUser({ state: false, data: errors }));
      }
   } catch (error:any) {
      dispatch(setUser({ state: false, data: error.message }));
   }
}