import api from "./api"
import { returningError } from "./private";

export const getBanners = async () => {
    try {
        const { data }:any = await api.get("/es/v1/banners");
        return { ...data, success: "true" };
    } catch (error:any) {
        return returningError(error);
    }
}

export const getCategories = async () => {
    try {
        const { data }:any = await api.get("/es/v1/categories", {
            params: {
                raw: true
            }
        });
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const getCountries = async () => {
    try {
        const { data }:any = await api.get("/es/v1/countries");
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const getEmployees = async (id="") => {
    try {
        const { data }:any = await api.get(`/es/v1/employees${id ? `/${id}` : ""}`);
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const getProducts = async () => {
    try {
        const { data }:any = await api.get("/es/v1/products", {
            params: {
                raw: true
            }
        });
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const getProductsByCategory = async (categoryId:string) => {
    try {
        const { data }:any = await api.get(`/es/v1/products?category=${categoryId}`, {
            params: {
                raw: true
            }
        });
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}

export const getZones = async (id?:any) => {
    try {
        const { data }:any = await api.get(id ? `/es/v1/zones/${id}` : "/es/v1/zones");
        return { ...data, success: "true" };
    } catch (error) {
        return returningError(error);
    }
}


