import { useEffect, useState } from "react";
import { useAppSelector } from "./useAppSelector";

const useAuth = () => {
    const { data } = useAppSelector(({user}) => user);
    const [isAuth, setIsAuth] = useState(data.token ? true : false);
    
    useEffect(() => {
        if (data.token) {
            return setIsAuth(true);
        }
        setIsAuth(false);
    }, [data.token])
    return isAuth;
}
export default useAuth;