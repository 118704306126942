import { AppLayout, Layout, NotFound } from "components";
import { useAppSelector } from "hooks";
import SignIn from "pages/auth/SignIn";
import { Events } from "pages/codes/events";
import { Hunters } from "pages/codes/hunters";
import { Home } from "pages/home";
import { DayServices, NextServices, ServicesHistorical, ServiceDetail } from "pages/services";
import { Banners, Categories, Countries, EditZone, GoodLooks, ManipulateGoodLook, Products, Zones } from "pages/settings";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import PrivateRoute from "./PrivateRoute";
import { setBanners, setCategories, setCountries, setProducts, setZones } from "store/catalogs/catalogs_t";

export default function AppRouter () {
    const { data } = useAppSelector(({ user }) => user);
    const { token } = data;
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(setBanners());
            dispatch(setProducts());
            dispatch(setZones());
            dispatch(setCountries());
            dispatch(setCategories());
        }
    }, [token])
    
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="/" element={<SignIn />} />
                <Route path="not-found" element={<NotFound />} />
            </Route>
            <Route element={<AppLayout />}>
                <Route
                    path="home"
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />
                <Route path="services">
                    <Route
                        index
                        element={
                            <Navigate replace to={"day-services"}/>
                        }
                    />
                    <Route
                        path="day-services"
                        element={
                            <PrivateRoute>
                                <DayServices />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="nexts"
                        element={
                            <PrivateRoute>
                                <NextServices />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="historical"
                        element={
                            <PrivateRoute>
                                <ServicesHistorical />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="detail/:id"
                        element={
                            <PrivateRoute>
                                <ServiceDetail />
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route
                    path="coupons"
                    element={
                        <PrivateRoute>
                            <Events />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="hunters"
                    element={
                        <PrivateRoute>
                            <Hunters />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="countries"
                    element={
                        <PrivateRoute>
                            <Countries />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="zones"
                    element={
                        <PrivateRoute>
                            <Zones />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="zones/:id"
                    element={
                        <PrivateRoute>
                            <EditZone  />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="categories"
                    element={
                        <PrivateRoute>
                            <Categories />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="products"
                    element={
                        <PrivateRoute>
                            <Products />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="banners"
                    element={
                        <PrivateRoute>
                            <Banners />
                        </PrivateRoute>
                    }
                />
                <Route path="goodlooks">
                    <Route
                        index
                        element={
                            <PrivateRoute>
                                <GoodLooks />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="add"
                        element={
                            <PrivateRoute>
                                <ManipulateGoodLook />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <PrivateRoute>
                                <ManipulateGoodLook />
                            </PrivateRoute>
                        }
                    />
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="not-found"/>} />
        </Routes>
    )
}