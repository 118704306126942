import { Empty, notification } from "antd";
import esES from 'antd/lib/locale/es_ES';
import { getHistoricalServices } from "api";
import { InternalPageLayout, Table } from "components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cleanCurrentService } from "store/service";
import { dayServicesColumns } from "utils/tableColumns";

const ServicesHistorical = () => {
    const [services, setServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const gettingServices = async () => {
        if (!isLoading)
            setIsLoading(true);
        try {
            const {data, errors} = await getHistoricalServices();
            if (data) {
                setServices(data);
            } else {
                setServices([]);
                notification.error({
                    message: "Listado de Historico",
                    description: errors
                });
            }
            setIsLoading(false);
        } catch (error:any) {
            setServices([]);
            setIsLoading(false);
            notification.error({
                message: "Listado de Historico",
                description: error.message
            });
        }
    }

    useEffect(() => {
        gettingServices();
        dispatch(cleanCurrentService());
    }, [])

    return (
        <InternalPageLayout title="Historial de Servicios">
            <Table
                localeProv={esES}
                columns={dayServicesColumns(navigate, dispatch)}
                dataSource={services}
                size="small"
                rowKey={'id'}
                // dataSource={search ? filterHunters() : hunters}
                locale={{
                    triggerDesc: 'Ordenar de forma descendente',
                    triggerAsc: 'Ordenar de forma ascendente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No hay datos"
                    />
                    )
                }}
                scroll={{ x: 'auto' }}
                pagination={{ defaultPageSize: 10 }}
                loading={isLoading}
            />
        </InternalPageLayout>
    )
}
export default ServicesHistorical;
