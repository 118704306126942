import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'store/globalactions';
import { HuntersSchema } from 'store/schema';

export const huntersSlice = createSlice({
   name: 'HUNTERS',
   initialState: HuntersSchema,
   reducers: {
      eventsIsFetching: (state) => { 
         state.promoCodes.status.fetching = true;
         state.promoCodes.status.state = 'FETCHING';
         state.promoCodes.errorMessage = '';
         state.promoCodes.data = [];
      },
      setPromoCodes: (state, { payload }) => {
         state.promoCodes.status.fetching = false;
         state.promoCodes.status.state = payload.status ? 'SUCCESS' : 'ERROR';
         state.promoCodes.errorMessage = payload.status ? '' : payload.data;
         state.promoCodes.data = payload.status ? payload.data : [];
      },
      huntersIsFetching: (state) => {
         state.hunters.status.fetching = true;
         state.hunters.status.state = 'FETCHING';
         state.hunters.errorMessage = '';
         state.hunters.data = [];
      },
      setAllHunters: (state, { payload }) => {
         state.hunters.status.fetching = false;
         state.hunters.status.state = payload.status ? 'SUCCESS' : 'ERROR';
         state.hunters.errorMessage = payload.status ? '' : payload.data;
         state.hunters.data = payload.status ? payload.data : [];
      },
      cleanCurrentEvent: (state) => {
         state.event = {};
      },
      setCurrentEvent: (state, { payload }) => {
         state.event = payload;
      },
      cleanCurrentHunter: (state) => {
         state.hunter = {};
      },
      setCurrentHunter: (state, { payload }) => {
         state.hunter = payload;
      }
   },
   extraReducers: (builder) => {
      builder.addCase(logout, () => {
        return HuntersSchema;
      });
    },
});

export const {
   eventsIsFetching, setPromoCodes, huntersIsFetching, setAllHunters,
   cleanCurrentEvent, setCurrentEvent, cleanCurrentHunter, setCurrentHunter
} = huntersSlice.actions;
