import { Button as BootstrapButton, ButtonProps, Spinner } from "react-bootstrap";

interface ButtonPropsI extends ButtonProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
    loading?: boolean;
    disabled?: boolean;
    animation?: 'border' | 'grow'
    spinnerSize?: 'sm' | undefined
    spinnerClassName?: string
}

const Button = ({ children, loading, disabled,  animation='border', spinnerSize='sm', spinnerClassName, ...rest }:ButtonPropsI) => {
    return (
        <BootstrapButton {...rest} disabled={disabled}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {loading &&
               <Spinner
                  className={spinnerClassName}
                  style={{ marginRight: '0.3em' }}
                  animation={animation}
                  size={spinnerSize}
                  as='span'
                  role='status'
                  aria-hidden={true}
               />
            }
            {children}
            </div>
        </BootstrapButton>
    )
}
export default Button;
